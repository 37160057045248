<table class="table table-striped">
  <thead>
      <tr>
          <th class="text-center" *ngFor="let param of Model.params">
              {{param.alias | translate}}
          </th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let item of itemList">
          <td *ngFor="let param of Model.params">
              <row-model class="text-center" [record]="item" [param]="param"
                  [model]="Model"></row-model>
          </td>
      </tr>
  </tbody>
</table>

<pagination-component [currentPage]="currentPage" [lastPage]="lastPage" (onPageChage)="changePage($event)"></pagination-component>
