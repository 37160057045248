<div class="date-picker-container col-md-12">
    <div class="date-picker-separator">
      <label for="date">
        <span *ngIf="!hideLabel" [ngClass]="{'error-label': error}">
          {{ translateTerm(param.alias) }}
        </span>
        <tooltip-component [sentence]="param.tooltip"></tooltip-component>
        <note [text]="param.note"></note>
        <p *ngIf="error" [ngClass]="{'error-label': error}">
          <small [innerHTML]="error"></small>
        </p>
        <input
            type="text"
            class="form-control"
            placeholder="DD-MM-YYYY"
            [value]="displayDateString" 
            readonly
            (click)="toggleDatepicker(true)" 
        />
        <input
        type="text"
        style="visibility: hidden;"
        class="form-control d-none"
        placeholder="Click to pick a date"
        bsDatepicker
        [isOpen]="isDatepickerOpen"
        [(ngModel)]="date"
        (bsValueChange)="onDateChange($event)"
        [bsConfig]="{ locale: 'pt-br',isAnimated: true, dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue'}"
      />
      </label>
    </div>
  </div>
  <!-- dateInputFormat: 'DD/MM/YYYY',  -->