<div #styleContainer></div>
        <div class="sms-input-title">
            {{ getValue('ticketoutput-sms_screen_message')}}</div>

        <div id="kiosk-sms-screen-container">

            <div class="numeric-keyboard-container">
                <input class="sms-input" value="912345678">
                <div class="keyboard-row">
                    <div class="key">1</div>
                    <div class="key">2</div>
                    <div class="key">3</div>
                </div>
                <div class="keyboard-row">
                    <div class="key">4</div>
                    <div class="key">5</div>
                    <div class="key">6</div>
                </div>
                <div class="keyboard-row">
                    <div class="key">7</div>
                    <div class="key">8</div>
                    <div class="key">9</div>
                </div>
                <div class="keyboard-row">
                    <div class="key delete-button"><i class="fa fa-times"></i></div>
                    <div class="key">0</div>
                    <div class="key confirm-button"><i class="fa fa-angle-right"></i></div>
                </div>
            </div>
        </div>
