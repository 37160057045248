<label for="{{identifier}}" class="col-md-12" [formGroup]="formGroup">
    <span [ngClass]="{'error-label': error}">{{ translateTerm(param.alias)}}</span> <tooltip-component [sentence]="param.tooltip"></tooltip-component>
    <note [text]="param.note"></note>
    <p *ngIf="error" [ngClass]="{'error-label': error}"><small [innerHTML]="error"></small></p>

    <table class="table table-striped">
      <thead>
          <th *ngFor="let model of param.models">{{translateTerm(model.model.modelAliasSingular)}}</th>
      </thead>
      <tbody [formArrayName]="param.attr">
          <tr *ngFor="let control of formGroup.controls[param.attr]['controls']; let i = index;" [formGroup]="control">
              <td *ngFor="let model of param.models">
                  <select [formControlName]="model.model.modelSingular">
                      <option value="">{{translateTerm('Choose an option')}}</option>
                      <option *ngFor="let item of relations[model.model.modelSingular]" value="{{item[model.key]}}">{{item[model.value]}}</option>
                  </select>
              </td>
              <td><a (click)="removeRow(i)">X</a></td>
          </tr>
          {{setValue()}}
      </tbody>
    </table>

    <a class="btn btn-primary" (click)="addRelationRow()">{{ translateTerm('Add')}} {{ translateTerm(param.alias)}}</a>
  </label>
