<div [formGroup]="formGroup" class="col-md-12 form-group">
  <div class="row">
    <div class="col-xs-4">
      <label for="day">{{ label }}</label>
    </div>
    <div class="col-xs-4">
      <label for="start">Ínicio</label>
      <input type="time" class="form-control" formControlName="start">
    </div>
    <div class="col-xs-4">
      <label for="fim">Fim</label>
      <input type="time" class="form-control" formControlName="end">
    </div>
  </div>
</div>