<div class="form-element-spacing">
    <span class="label-many-form"> {{this.param.alias | translate}}</span>
    <tooltip-component [sentence]="this.param.tooltip"></tooltip-component>
    <p *ngIf="error" [ngClass]="{'error-label': error}"><small [innerHTML]="error"></small></p>

<table *ngIf="formGroup.controls[param.attr].controls && formGroup.controls[param.attr].controls.length" class="table table-bordered table-striped tex">
    <thead>
        <ng-container  *ngFor="let item of fields">

            <th *ngIf="paramIsValid(item) &&  item.type != 'hidden'" class="text-center">
                {{ item.alias | translate }}
            </th>
        </ng-container >
    </thead>
    <tbody>
        <tr *ngFor="let field of formGroup.controls[param.attr].controls;  let i = index;">
            <ng-container *ngFor="let fieldParam of fields">

                <td  *ngIf="paramIsValid(fieldParam) && fieldParam.type != 'hidden'">
                    <input-default *ngIf="getInputTemplate(fieldParam.type) == 'input'" [hideLabel]="true" [identifier]="getIdentifierforelement(i, fieldParam)" [Model]="Model" [formGroup]="field"  [param]="fieldParam"></input-default>
                    <time-picker *ngIf="getInputTemplate(fieldParam.type) == 'time'" [hideLabel]="true" [identifier]="getIdentifierforelement(i, fieldParam)" [Model]="Model" [formGroup]="field"  [param]="fieldParam" [record]="getRecord(i)"></time-picker>
                    <date-picker *ngIf="getInputTemplate(fieldParam.type) == 'date'" [hideLabel]="true" [identifier]="getIdentifierforelement(i, fieldParam)" [Model]="Model" [formGroup]="field"  [param]="fieldParam" [record]="getRecord(i)"></date-picker>
                    <textarea-input *ngIf="getInputTemplate(fieldParam.type) == 'textarea'" [hideLabel]="true" [identifier]="getIdentifierforelement(i, fieldParam)" [Model]="Model" [formGroup]="field"  [param]="fieldParam"></textarea-input>
                    <dropdown (changeValueEmitter)="updateDependingLists($event)" *ngIf="getInputTemplate(fieldParam.type) == 'dropdown'"  [hideLabel]="true" [identifier]="getIdentifierforelement(i, fieldParam)" [Model]="Model"  [formGroup]="field"  [relations]="getRelations(fieldParam)" [param]="fieldParam" [record]="getRecord(i)"></dropdown>
                    <dropdown-simple (changeValueEmitter)="updateDependingLists($event)" *ngIf="getInputTemplate(fieldParam.type) == 'dropdown-simple'"  [hideLabel]="true" [identifier]="getIdentifierforelement(i, fieldParam)" [Model]="Model"  [formGroup]="field"  [relations]="getRelations(fieldParam)" [param]="fieldParam" [record]="getRecord(i)"></dropdown-simple>
                    <checkbox-single *ngIf="getInputTemplate(fieldParam.type) == 'checkbox-single'" [Model]="Model" [identifier]="getIdentifierforelement(i, fieldParam)" [hideLabel]="true"  [formGroup]="field"  [param]="fieldParam" [record]="getRecord(i)"></checkbox-single>
                    <checkbox-list *ngIf="getInputTemplate(fieldParam.type) == 'checkbox-list'" [identifier]="getIdentifierforelement(i, fieldParam)" [hideLabel]="true" [Model]="Model" [relations]="getRelations(fieldParam)" [formGroup]="field" [param]="fieldParam" [record]="getRecord(i)"></checkbox-list>
                    <hidden-field *ngIf="getInputTemplate() == 'hidden'"   [formGroup]="field" [identifier]="getIdentifierforelement(i, fieldParam)" [param]="fieldParam"></hidden-field>
                    <auto-default  *ngIf="fieldParam.type == 'autoDefault'" [hideLabel]="true"   [param]="fieldParam" [formGroup]="field" [identifier]="getIdentifierforelement(i, fieldParam)"></auto-default>
                </td>
            </ng-container>
            <td *ngIf="!getDisabledByUserDisabled(field)">
                    <a *ngIf="!getDisabledByUserDisabled(field)" type="button" class="btn btn-danger" (click)="removeRow(i)"><span class="glyphicon glyphicon-trash"></span></a>
            </td>
        </tr>
    </tbody>
</table>
<div>
    <a class="btn btn-primary" (click)="addRelationRow()">{{'Add' | translate}} {{ param.alias |translate}}</a>
</div>
</div>
