<breadcrumb  [routeBreadcrumb]="breadcrumb"></breadcrumb>

<h2 *ngIf="dataReady" >{{getTitle()}} </h2>
<hr>
<div *ngIf="dataReady" class="row">
  <div class="col-md-12">
    <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="text-center" *ngFor="let param of getParamsByPermissions()">
                {{param.alias | translate}}
                <span (click)="sortField(param.attr)" *ngIf="param.options.sortable" class="sort-btn fa" [ngClass]="{'fa-sort-desc': sort.param == param.attr && sort.order == 'desc', 'fa-sort-asc': sort.param == param.attr && sort.order == 'asc', 'fa-sort': sort.param != param.attr}"></span></th>
              <th *ngIf="hasListOptions()"><span class="fa fa-cog"></span></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of results">
              <td *ngFor="let param of getParamsByPermissions()">
                  <row-model class="text-center" [record]="item" [param]="param" [model]="modelInstance"></row-model>
              </td>
              <td *ngIf="hasListOptions()">
                  <div class="tools-row">

                      <list-option-device-tasks *ngFor="let option of getItemOptions('device-tasks', item)" [Model]="Model" [item]="item" [option]="option"></list-option-device-tasks>
                      <list-option-endpoints *ngFor="let option of getItemOptions('endpoints', item)" [Model]="Model" [item]="item" [option]="option"></list-option-endpoints>
                      <list-option-url *ngFor="let option of getItemOptions('url', item)" [Model]="Model" [item]="item" [option]="option" [agencyId]="agencyId"></list-option-url>
                      <list-option-post-confirm *ngFor="let option of getItemOptions('post-confirm', item)" [Model]="Model" [item]="item" [option]="option"></list-option-post-confirm>
                      <a *ngIf="downloadSettings(item)" [href]="downloadSettings(item)" class="fa fa-download"></a>
                    </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-component [currentPage]="currentPage" [lastPage]="lastPage" [totalRecords]="totalRecords" [recordsPerPage]="recordsPerPage" (onPageChage)="callPage($event)"></pagination-component>
  </div>
</div>
