<div class="main-outter-div">
    <div class="app-row col-md-12">
        <div class="col-md-12">
            <h2>{{translateTerm("Device monitoring") + ': ' + deviceName}}</h2>
        </div>
        <div class="col-md-12">
            <p class="pull right"><b>{{translateTerm("Device Status") + ': '}} </b>
                <span *ngIf="deviceStatus == 'Online'"
                    class="text-success label label-success">{{translateTerm(deviceStatus)}}</span>
                <span *ngIf="deviceStatus == 'Offline'"
                    class="text-danger label label-danger">{{translateTerm(deviceStatus)}}</span>
                <span *ngIf="deviceStatus == 'Unavailable' || !deviceStatus"
                    class="text-muted label label-default">{{translateTerm(deviceStatus)}}</span>
                <b>{{translateTerm("Last Seen")}}:</b> {{getLastSeen()}}
            </p>
        </div>
        <div class="col-md-12 action-buttons-container">
            <list-option-device-tasks *ngIf="device && checkAcl('actionDevices')" (onTaskRequest)="askForInfo()" [item]="device"
                [Model]="deviceInfoInstance" [option]="option">
            </list-option-device-tasks>
            <button (click)="askForInfo()" class="btn btn-warning pull-right"><span
                    class="glyphicon glyphicon-refresh"></span>{{' '+translateTerm('Update')}}</button>
        </div>
        <tabset>
            <tab *ngIf=" 'systemDevice' | hasPermission" heading="{{translateTerm('System')}}" class="mt-2">

                <div class="col-md-12" *ngIf="deviceInfoFound">
                    <div class="col-md-6">

                        <div *ngIf="system" class="col-md-12 padding-border">
                            <h3><b>{{translateTerm('System')}}</b></h3>
                            <div *ngIf="system['model']"><b>model: </b>{{system['model']}}</div>
                            <div *ngIf="system['serial']"><b>serial: </b>{{system['serial']}}</div>
                            <div *ngIf="system['load_average']"><b>load_average: </b>{{system['load_average'].join(", ")}}</div>
                            <div *ngIf="system['version']"><b>version: </b>{{version}}</div>
                            <div *ngIf="system['device_id']"><b>device_id: </b>{{system['device_id']}}</div>
                            <div *ngIf="system['hostname']"><b>hostname: </b>{{system['hostname']}}</div>
                            <div *ngIf="system['last_boot']"><b>last_boot: </b>{{system['last_boot']}}</div>
                            <div *ngIf="system['timezone']"><b>timezone: </b>{{system['timezone']}}</div>
                            <div *ngIf="system['distribution']"><b>distribution:
                                </b>{{system['distribution'] | slice:0:19 }}
                            </div>
                        </div>
                        <div *ngIf="cpu" class="col-md-12 padding-border">
                            <h3><b>CPU</b></h3>
                            <div *ngIf="cpu['cpu_percent']"><b>cpu_percent: </b>{{cpu['cpu_percent']}}</div>
                            <div *ngIf="cpu['processor']"><b>processor: </b>{{cpu['processor']}}</div>
                            <div *ngIf="cpu['cpu_count']"><b>cpu_count: </b>{{cpu['cpu_count']}}</div>
                            <div *ngIf="cpu['model']"><b>model: </b>{{cpu['model']}}</div>
                        </div>
                        <div *ngIf="videoCard" class="col-md-12 padding-border">
                            <h3><b>Video Card</b></h3>
                            <div *ngIf="videoCard.vendor"><b>Vendor: </b>{{videoCard.vendor}}</div>
                            <div *ngIf="videoCard.product"><b>product: </b>{{videoCard.product}}</div>
                            <div *ngIf="videoCard.description"><b>model: </b>{{videoCard.description}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div *ngIf="memory" class="col-md-12 padding-border">
                            <h3><b>{{translateTerm('Memory Usage (RAM)')}}</b></h3>
                            <div *ngIf="memory['percent']"><b>percent: </b>{{memory['percent']}}</div>
                            <div *ngIf="memory['available']"><b>available: </b>{{memory['available']}} Bytes |
                                {{ formatBytes(memory['available'])}}
                            </div>
                            <div *ngIf="memory['used']"><b>used: </b>{{memory['used']}} Bytes |
                                {{ formatBytes(memory['used'])}}
                            </div>
                            <div *ngIf="memory['free']"><b>free: </b>{{memory['free']}} Bytes |
                                {{ formatBytes(memory['free'])}}
                            </div>
                            <div *ngIf="memory['total']"><b>total: </b>{{memory['total']}} Bytes |
                                {{ formatBytes(memory['total'])}}
                            </div>
                        </div>

                        <div class="col-md-12 padding-border" *ngFor="let disk of disks">
                            <h3><b>{{translateTerm('Disk Usage')}}</b></h3>
                            <div *ngIf="disk.partition"><b>partition: </b>{{disk.partition}}</div>
                            <div *ngIf="disk.device"><b>device: </b>{{disk.device}}</div>
                            <div *ngIf="disk.fstype"><b>fstype: </b>{{disk.fstype}}</div>
                            <div *ngIf="disk.percent"><b>percent: </b>{{disk.percent}}</div>
                            <div *ngIf="disk.size"><b>size: </b>{{disk.size}} Bytes | {{ formatBytes(disk.size)}}</div>
                            <div *ngIf="disk.used"><b>used: </b>{{disk.used}} Bytes | {{ formatBytes(disk.used)}}</div>
                        </div>
                    </div>
                </div>

                <div *ngIf="!deviceInfoFound" class="col-md-4 padding-border">
                    No momento não existe informação sobre este dispositivo.
                </div>
            </tab>
            <tab *ngIf=" 'networkDevice' | hasPermission" heading="{{translateTerm('Network')}}" class="mt-2">
                <div class="col-md-12" *ngIf="deviceInfoFound">
                    <div class="col-md-12 padding-border">
                        <h3><b>{{translateTerm('Network')}}</b></h3>
                        <div *ngFor="let network of networks" class="col-md-4 padding-border">
                            <div *ngIf="network['dropin']!==undefined"><b>dropin: </b> {{network['dropin']}}</div>
                            <div *ngIf="network['dropout']!==undefined"><b>dropout: </b> {{network['dropout']}}</div>
                            <div *ngIf="network['id']!==undefined"><b>id: </b> {{network['id']}}</div>
                            <div *ngIf="network['errout']!==undefined"><b>errout: </b> {{network['errout']}}</div>
                            <div *ngIf="network['errin']!==undefined"><b>errin: </b> {{network['errin']}}</div>
                            <div *ngIf="network['macaddress']!==undefined"><b>macaddress: </b> {{network['macaddress']}}
                            </div>
                            <div *ngIf="network['dhcp']!==undefined"><b>dhcp: </b> {{network['dhcp']? 'Yes' : 'No'}}
                            </div>
                            <div *ngIf="network['dns']!==undefined"><b>dns: </b> {{network['dns']}}
                            </div>
                            <div *ngIf="network['packets_sent']!==undefined"><b>packets_sent: </b>
                                {{network['packets_sent']}}
                            </div>
                            <div *ngIf="network['packets_recv']!==undefined"><b>packets_recv: </b>
                                {{network['packets_recv']}}
                            </div>
                            <div *ngIf="network['bytes_sent']!==undefined"><b>bytes_sent: </b> {{network['bytes_sent']}}
                            </div>
                            <div *ngIf="network['bytes_recv']!==undefined"><b>bytes_recv: </b> {{network['bytes_recv']}}
                            </div>
                            <div *ngIf="network['ips']!==undefined"><b>ip's: </b>
                                <div class="col-md-12 padding-border-bottom" *ngFor="let ip of network['ips']">
                                    <div *ngIf="ip.broadcast!==undefined"><b>broadcast: </b>{{ip.broadcast}}</div>
                                    <div *ngIf="ip.netmask!==undefined"><b>netmask: </b>{{ip.netmask}}</div>
                                    <div *ngIf="ip.gateway!==undefined"><b>gateway: </b>{{ip.gateway}}</div>
                                    <div *ngIf="ip.address!==undefined"><b>address: </b>{{ip.address}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!deviceInfoFound" class="col-md-4 padding-border">
                    No momento não existe informação sobre este dispositivo.
                </div>
            </tab>
            <tab *ngIf=" 'servicesDevice' | hasPermission" heading="{{translateTerm('Services')}}" class="mt-2">
                <div class="col-md-12" *ngIf="deviceInfoFound">
                    <div class="col-md-12  padding-border">
                        <h3><b>{{translateTerm('Services')}}</b></h3>
                        <div *ngFor="let service of services"
                            class="col-md-4 padding-border {{getServiceStatusClass(service)}}">
                            <h3><b>{{service.name}}</b></h3>
                            <div><b>status: </b> {{service.info.status}}</div>
                            <div><b>uptime: </b> {{service.info.uptime}}</div>
                            <div><b>memory: </b> {{service.info.memory}}</div>
                        </div>
                        <div *ngFor="let service of ssdpList"
                            class="col-md-4 padding-border {{getServiceStatusClass(service)}}">
                            <h3><b>{{service.name}}</b></h3>
                            <div><b>status: </b> {{service.info.status}}</div>
                            <div><b>uptime: </b> {{service.info.uptime}}</div>
                        </div>
                    </div>
                    <div class="col-md-12 padding-border" *ngIf=" 'checksDevice' | hasPermission">
                        <h3><b>{{translateTerm('Checks')}}</b></h3>
                        <div *ngFor="let check of checks">
                            <div class="col-md-4 padding-border checks {{getChecksStatusClass(check)}}">
                                <div><b>Scope: </b> {{check.scope}}</div>
                                <div><b>Status: </b> {{check.status}}</div>
                                <div><b>Date: </b> {{convertDate(check.timestamp)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!deviceInfoFound" class="col-md-4 padding-border">
                    No momento não existe informação sobre este dispositivo.
                </div>
            </tab>
            <tab *ngIf=" 'databaseDevice' | hasPermission" heading="{{translateTerm('Database')}}" class="mt-2">
                <div class="col-md-12" *ngIf="deviceInfoFound">
                    <div class="col-md-4">
                        <h3><b>{{translateTerm('Mongo Count')}}</b></h3>
                        <div class="padding-border">
                            <div *ngFor="let count of mongo_count">
                                <div><b>{{count.name}}: </b> {{count.value}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="last_card_update || last_client_update" class="col-md-4">
                        <h3><b>{{translateTerm('Client Import Data')}}</b></h3>
                        <div class="padding-border">
                            <div>
                                <div><b>last_card_update: </b> {{last_card_update}}</div>
                                <div><b>last_client_update: </b> {{last_client_update}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="databaseConnections" class="col-md-4">
                        <h3><b>{{'Database connections' | translate}}</b></h3>
                        <div class="padding-border">
                            <div>
                                <div><b>current: </b> {{databaseConnections.current ? databaseConnections.current : 'NA' }}</div>
                                <div><b>active: </b> {{databaseConnections.active ? databaseConnections.active : 'NA' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6">
                        <h3><b>{{'Mongo last update'| translate}}</b></h3>
                        <div class="padding-border">
                            <div *ngFor="let lastupdate of mongoLastUpdate">
                                <div><b>{{lastupdate.name}}: </b> {{convertDate(lastupdate.value)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!deviceInfoFound" class="col-md-4 padding-border">
                    No momento não existe informação sobre este dispositivo.
                </div>
            </tab>
            <tab *ngIf=" 'peripheralsDevice' | hasPermission" heading="{{translateTerm('Peripherals')}}" class="mt-2">
                <div class="col-md-12" *ngIf="deviceInfoFound">
                    <div class="col-md-6">
                        <div class="padding-border">
                            <h3><b>{{translateTerm('USB')}}</b></h3>
                            <div class="padding-border" *ngFor="let usbConnected of usb">
                                <div><b>Device: </b> {{usbConnected.device}}</div>
                                <div><b>Tag: </b> {{usbConnected.tag}}</div>
                                <div><b>Id: </b> {{usbConnected.id}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="padding-border">
                            <h3><b>{{translateTerm('Printer Status')}}</b></h3>
                            <div><b>Status: </b> {{printer.status}}</div>
                            <div *ngIf="printer.message"><b>Message: </b> {{printer.message}}</div>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="externalDevices && externalDevices.length">
                        <div class="padding-border">
                            <h3><b>{{translateTerm('Device')}}</b></h3>
                            <div class="padding-border" *ngFor="let deviceName of externalDevices">
                                <b>Device: </b>{{deviceName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!deviceInfoFound" class="col-md-4 padding-border">
                    No momento não existe informação sobre este dispositivo.
                </div>
            </tab>
            <tab *ngIf=" 'listHardwareModels' | hasPermission" heading="{{'Hardware' | translate}}" class="mt-2">
                <div class="row">
                    <div class="col-md-12 action-buttons-container">
                        <device-hardware-manager [deviceId]="deviceId" [mode]="'create'" (onOperation)="askForInfo()"></device-hardware-manager>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>{{ 'Brand' | translate }}</th>
                                        <th>{{ 'Model' | translate }}</th>
                                        <th>{{ 'Type' | translate }}</th>
                                        <th>{{ 'Status' | translate }}</th>
                                        <th>{{ 'Action' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let deviceHardware of deviceHardwares">
                                        <td>
                                            {{deviceHardware.hardware_model.brand}}
                                        </td>
                                        <td>
                                            {{deviceHardware.hardware_model.model}}
                                        </td>
                                        <td>
                                            {{deviceHardware.hardware_model.hardware_type.name}}
                                        </td>
                                        <td *ngIf="deviceHardware.active == 1">
                                            {{ 'Active' | translate}}
                                        </td>
                                        <td *ngIf="deviceHardware.active != 1">
                                            {{ 'Deactivated' | translate}}
                                        </td>
                                        <td>
                                            <device-hardware-manager [deviceId]="deviceId" [mode]="'delete'" [hardwareModel]="deviceHardware" (onOperation)="askForInfo()"></device-hardware-manager>
                                            <device-hardware-manager [deviceId]="deviceId" [mode]="'edit'" [hardwareModel]="deviceHardware" (onOperation)="askForInfo()"></device-hardware-manager>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </tab>
            <tab *ngIf=" 'notesDevice' | hasPermission" heading="{{ 'Notes' | translate}}" class="mt-2">
                <div class="row">
                    <div class="col-md-12 action-buttons-container">
                        <device-note-editor [deviceId]="deviceId" (onOperation)="askForInfo()"></device-note-editor>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>{{ 'User' | translate }}</th>
                                        <th>{{ 'Note' | translate }}</th>
                                        <th>{{ 'Private' | translate }}</th>
                                        <th>{{ 'Date of creation' | translate }}</th>
                                        <th>{{ 'Date of update' | translate }}</th>
                                        <th>{{ 'Action' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let deviceNote of deviceNotes">
                                        <td>
                                            {{deviceNote.user.name}}
                                        </td>
                                        <td>
                                            {{deviceNote.note}}
                                        </td>
                                        <td>
                                            {{deviceNote.private ? 'yes' : 'No' | translate}}
                                        </td>
                                        <td>
                                            {{deviceNote.created_at | convertToTimezone}}
                                        </td>
                                        <td>
                                            {{deviceNote.updated_at | convertToTimezone}}
                                        </td>
                                        <td>
                                            <device-note-editor [note]="deviceNote" (onOperation)="askForInfo()"></device-note-editor>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </tab>
            <tab *ngIf=" 'logsDevice' | hasPermission" heading="{{translateTerm('Logs')}}" class="mt-2">
                <div class="col-md-12 padding-border" *ngIf="deviceInfoFound">
                    <log-list [logGroup]="logGroup"></log-list>
                </div>
                <div *ngIf="!deviceInfoFound" class="col-md-4 padding-border">
                    No momento não existe informação sobre este dispositivo.
                </div>
            </tab>
            <tab *ngIf="('tasksDevices' | hasPermission)" heading="{{translateTerm('Device Tasks')}}" class="mt-2">
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <simple-list [Model]="TaskSchedule" [itemList]="taskScheduleList" [currentPage]="taskScheduleCurrentPage" [lastPage]="taskScheduleLastPage" (onPageChage)="updateTaskScheduleList($event)" ></simple-list>
                        </div>
                    </div>
                </div>
            </tab>
            <tab *ngIf=" 'databaseUpdatesDevice' | hasPermission" heading="{{translateTerm('Database updates')}}" class="mt-2">
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Table</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of deviceDatabaseLogs">
                                        <td>
                                            {{item.table_name}}
                                        </td>
                                        <td>
                                            {{item.created_at | convertToTimezone}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </tab>
            <tab *ngIf=" 'screenDevice' | hasPermission" heading="{{translateTerm('Screen')}}" class="mt-2">
                <div class="row">
                    <div class="col-md-12 padding-border" *ngIf="screen && screen !== null">
                        <div class="col-md-4 padding-border"><b>Screen Properties: </b>
                            <div *ngIf="screen.height"><b>Height: </b>{{getHeight()}} px</div>
                            <div *ngIf="screen.width"><b>Width: </b>{{getWidth()}} px</div>
                            <div *ngIf="screen.rotation"><b>Rotation: </b>{{screen.rotation}}</div>
                            <hr>
                            <div *ngIf="screen.edid_data && screen.edid_data.length > 0">
                                <div *ngFor="let data of screen.edid_data; index as i">
                                    <h4>{{translateTerm('Screen')}} {{i}}: </h4>
                                    <b>Manufacturer Properties: </b>
                                    <div><b>Manufacturer: </b> {{data.manufacturer}}</div>
                                    <div><b>Model: </b>{{data.name}}</div>
                                    <div><b>Serial: </b> {{data.serial}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="screen.snapshot"><b>Snapshot: </b>

                            <div *ngIf="screen.snapshot">
                                <div class="slidecontainer col-md-6">
                                    <label for="">Escala : {{scaleT}}</label>
                                    <input type="range" min="0" max="100" value="30" class="slider" id="myRange"
                                        (change)="changeScale($event)">
                                </div>
                                <img *ngIf="screen.snapshot" style="transform: scale(0.3);" class="screen-snapshot" id="device-snapshot" [width]="getWidth()"
                                    [height]="getHeight()" [src]="srcData" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!deviceInfoFound" class="col-md-4 padding-border">
                        No momento não existe informação sobre este dispositivo.
                    </div>
                </div>
            </tab>
            <tab heading="{{translateTerm('Shell tasks')}}" *ngIf="('shell_tasks' | hasPermission)" class="mt-2">
                <div class="row">
                    <div class="col-md-12 padding-border">
                        <shell-tasks [refreshEmitter]="refreshEmitter" [device_id]="this.deviceId"></shell-tasks>
                    </div>
                </div>
            </tab>
            <tab heading="{{translateTerm('Raw info')}}" *ngIf="('raw_info' | hasPermission)" class="mt-2">
                <div class="row">
                    <div class="col-md-12 padding-border">
                        <pre>{{ deviceInfoFull | json}}</pre>
                    </div>
                    <div *ngIf="!deviceInfoFound" class="col-md-4 padding-border">
                        No momento não existe informação sobre este dispositivo.
                    </div>
                </div>
            </tab>
        </tabset>
    </div>

</div>