<table class="table table-striped">
    <thead>
        <tr>
            <th>{{'Number Of Call' | translate}}</th>
            <th>{{'Service' | translate}}</th>
            <th>{{'Generation Date' | translate}}</th>
            <th>{{'Finishing Date' | translate}}</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="haveTicket(ticketArray); then haveTickets ;else notHaveTickets"></ng-container>

        <ng-template #haveTickets>
            <tr *ngFor="let ticket of ticketArray;">
                <td>{{ticket.number}}</td>
                <td>{{getServiceName(ticket.original_service_id)}}</td>
                <td>{{parseDate(ticket.dateCreated)}}</td>
                <td>{{parseDate(ticket.endDate)}}</td>
                <td><a [routerLink]="'/agency/'+this.agency.id+'/ticket/'+ticket._id" class="fa fa-eye"></a></td>
            </tr>
        </ng-template>
        

        <ng-template #notHaveTickets>
            <tr>
                <td colspan="4" class="empty"> {{'No Records' | translate}}</td>
            </tr>
            
        </ng-template>
        
    </tbody>
</table>