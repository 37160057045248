<div class="login">
  <div class="logo">
      <img class="logo-img" src="/assets/images/way2queuemanagement_logo.svg" alt="">
  </div>
    <div class="panel panel-default login-panel">
      <div class="panel-body">
        <div>
          <span>A sua sessão terminou, use o botão abaixo para voltar a fazer login</span>
        </div>
        <button (click)="login()">Login</button>
      </div>
    </div>
    <div class="col-xs-12"></div>
</div>