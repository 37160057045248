<breadcrumb [routeBreadcrumb]="breadcrumbArray"></breadcrumb>
<h1>{{translateTerm('System Customize')}}</h1>
<div *ngIf="true" class="row">
    <div class="col-md-6 col-md-offset-3">
        <input type="text" class="form-control" placeholder="{{translateTerm('Search of by fields')}}: {{translateTerm('Pattern')}}" (keyup)="filterTranslations()" [(ngModel)]="searchQuery">
      </div>
    <div class="choice-idiom">
        <select (change)="getLang($event)">
            <option value="pt">{{translateTerm('Choose an option')}}</option>
            <option value="pt">Português</option>
            <option value="en">Inglês</option>
        </select>
    </div>
</div>
<table class="table">
  <thead>
    <tr>
      <th scope="col">{{translateTerm('Default')}}</th>
      <th scope="col">{{translateTerm('Translate')}}</th>
      <th scope="col">{{translateTerm('Customize')}}</th>
      <th scope="col">{{translateTerm('Edit')}}</th>
      <th scope="col">{{translateTerm('Save')}}</th>
      <th scope="col">{{translateTerm('Delete')}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let term  of termsToDisplay; let i = index;">
      <td >{{term.default}}</td>
      <td >{{term.value}}</td>
      <td *ngIf="!isEditable(i)">{{term.personalize}}</td>
      <td *ngIf="isEditable(i)"><input type="text" class="form-control input-term" value="{{term.personalize ? term.personalize : term.value}}" (blur)="setValue($event, term.default)"></td>
      <td><i [attr.id]="i" class="fa fa-edit" (click)="editTrans($event, i)"></i></td>
      <td  (click)="saveTrans($event, i)" ><i [attr.id]="i" class="fa fa-save"></i></td>
      <td  (click)="deleteTrans(term.default, term.personalize)" ><i [attr.id]="i" class="fa fa-trash"></i></td>
    </tr>
  </tbody>
</table>
<button type="button" class="btn btn-primary" (click)="forceUpdate()">{{translateTerm('Force Update')}}</button>
