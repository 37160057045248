<h3>{{'Pool preview' | translate}}</h3>

<div style="display: flex; flex-direction: column; justify-content: flex-start; align-items: center;">
    <div class="ticket alert alert-info " style="width: 100%;" *ngFor="let ticket of orderedTickets">
        <div class="">
            <div class="icons-container ">


            </div>
            <b class="ticket-number h4">{{ticket.service.identifier}}001</b><br>

            <b>Em espera:</b>{{ticket.previewDateCreated}}<br>

            <b>Posto:</b> Posto 1<br>
            <b>Serviço:</b> {{ ticket.service.name }}<br>
        </div>
        <div class=" scroller buttons-container">
        </div>
    </div>
</div>