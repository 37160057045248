<div class="col-md-12 extra-filter-main" *ngIf="checkAcl()">
  <label>
    <span>
      {{ filterDefinition.name | translate }}
    </span>
  </label>
  <angular4-multiselect
    *ngIf="filterDefinition.filterType != 'onOff' && filterDefinition.filterType != 'dateDiff'"
    [data]="dropdownList"
    [(ngModel)]="selectedItems"
    [settings]="dropdownSettings"
    (onSelect)="onItemSelect($event)"
    (onDeSelect)="OnItemDeSelect($event)"
    (onSelectAll)="onSelectAll($event)"
    (onDeSelectAll)="onDeSelectAll($event)"
  ></angular4-multiselect>
  <div class="clearfix"></div>
  <input
    *ngIf="filterDefinition.filterType == 'onOff'"
    [checked]="this.selectedItems.length > 0"
    (change)="emitOnOff($event.target.checked)"
    type="checkbox"
  />
  <div class="clearfix"></div>
  <div *ngIf="filterDefinition.filterType == 'dateDiff'">
    <div>
      <datediff-modal [item]="filterDefinition" [selectedFilters]="getValueDateRange()" (dateRangeEmitter)="getDateRange($event)"></datediff-modal>
    </div>
  </div>
</div>
