<a *ngIf="isAllowed()" title="Acções" class="list-option-endpoint fa fa-cogs" (click)="openActionsModal(template)"></a>

<ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{translateTerm('Actions')}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <select name="endpoints" id="endpoints" (change)="setAction($event)" class="form-control">
                <option value="0">{{translateTerm('Choice a option')}}</option>
                <option *ngFor="let item of endpointsList" value="{{item.key}}">
                {{item.value}}
                </option>
            </select>
        </div>
        <div class="form-group">
            <button type="button" class="btn btn-primary" (click)="requestAction()">Ok</button>
        </div>
    </div>
  </ng-template>
