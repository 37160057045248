<h2>{{ Model.modelAliasSingular | translate}}</h2>
<div class="col-md-4">
    <div class="col-md-12">
        <div class="form-group">
            <label class="col-md-12 no-margin">
                <span>{{'Name' | translate}}:</span>
            </label>
            <input class="form-control" id="ticketName" value="{{ticketName}}" (blur)="updateTicketName($event)"
                type="text">
        </div>
        <div class="form-group">
            <label class="col-md-12 no-margin">
                <span>{{'Template type' | translate}} :</span>
            </label>
            <select class="form-control"  [(ngModel)]="templateType">
                <option value="null">{{'Choose an option' | translate}}</option>
                <option *ngFor="let option of getTemplateTypes()" [value]="option.id">{{ option.name | translate}}</option>
            </select>
        </div>
        <div class="col-md-12 no-margin">
            <button class="btn btn-success pull-left" (click)="openActionsModal(template)">{{'Add Element' | translate}}</button>
        </div>

        <div *ngIf="formErrors.length" class="col-md-12 alert alert-danger" role="alert">
            <ul>
                <li *ngFor="let error of formErrors">{{error}}</li>
            </ul>
          </div>
    </div>
</div>

<div class="col-md-8">
    <div class="col-md-12">
    
    <tabset>
        <tab *ngFor="let language of getLanguages()"heading="{{language.alias | translate}}"  [active]="language.id == 2" (select)="currentLanguageId = language.id" class="mt-2">
        </tab>
    </tabset>
    </div>
    <div class="col-md-8 wrapper">
        <label class="col-md-12 text-center">
            <span>{{'Element position editor' | translate}}:</span>
        </label>
        <div class="container ticket-preview-container" id="ticket-preview" [dragula]='"ticket-stack"'>
            <div *ngFor="let item of getCurrentStack(); let i = index" id="{{i}}" class="col-md-12 ticket-element">
                <div class="col-md-12">
                    <call *ngIf="isExpectedType('4', item)"></call>
                    <room *ngIf="isExpectedType('5', item)"></room>
                    <barcode class="ticket-image-editor" *ngIf="isExpectedType('6', item)"></barcode>
                    <service *ngIf="isExpectedType('7', item)"></service>
                    <service-group *ngIf="isExpectedType('16', item)"></service-group>
                    <waittime  [waitTimeLabel]="waitTimeLabel"  *ngIf="isExpectedType('8', item)"></waittime>
                    <timestamp *ngIf="isExpectedType('9', item)"></timestamp>
                    <separator *ngIf="isExpectedType('1', item)"></separator>
                    <appointment *ngIf="isExpectedType('14', item)"></appointment>
                    <clientname *ngIf="isExpectedType('15', item)"></clientname>
                    <amount-of-people [waitinglabel]="waitinglabel" *ngIf="isExpectedType('13', item)"></amount-of-people>
                    <textblock  *ngIf="isExpectedName('Text Block', item)"></textblock>
                    <callbitmap [fontFamily]="callBitmapFont" [inverted]="callBitmapInverted" *ngIf="isExpectedName('Callbitmap', item)"></callbitmap>
                    <qrcode-tag *ngIf="isExpectedName('QR code', item)" [qrcodeContent]="callBitmapFont"></qrcode-tag>
                    <p *ngIf="isExpectedType('3', item)" [innerHtml]="getValue(item)"></p>
                    <img class="ticket-image-editor" *ngIf="isExpectedType('2', item)" [src]="getValue(item)">
                    <div class="col-md-12 no-margin">
                        <a type="button" class="btn btn-sm btn-default pull-right "
                            (click)="editItem(item, template, i)"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">

        <label class="col-md-12 no-margin">
            <span>{{'Preview of the file' | translate}}: template.html:</span>
        </label>
        <textarea name="message" disabled rows="20" style="width:100%;">{{outputTicket()}}</textarea>
    </div>
</div>
<div class="col-md-12 form-group">
    <div class="form-btn-padding pull-left">
        <a type="button" class="btn btn-success" (click)="submitTicketLayout()">{{ 'Save' | translate}}</a>
    </div>
    <div class="form-btn-padding pull-left">
        <a type="button" class="btn btn-danger" (click)="goBack()">{{'Cancel' | translate}}</a>
    </div>
</div>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'Add Element' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-md-12">
            <div name="options" *ngFor="let option of ticketLayoutOptions" id="endpoints"
                (click)="selectItem(option.id)" style="margin: 10px 0;" class="col-md-4">
                <a type="button" class="btn btn-{{getButtonClass(option.id)}}" style="width:100%;">
                    {{option.name}}
                </a>
            </div>
        </div>
        <div *ngIf="isType('2')" class="col-md-12 element-preview" id="insert">
            <label class="col-md-12 text-center">
                <span>{{'Image Upload' | translate}}:</span>
            </label>
            <table class="table table-striped table-bordered table-hover table-condensed" *ngIf="isType('2')">
                <thead>
                    <th class="text-center"> Largura do papel(mm)</th>
                    <th class="text-center"> Largura maxima da imagem (px)</th>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            60 mm
                        </td>
                        <td>
                            380px
                        </td>
                    </tr>
                    <tr>
                        <td>
                            80 mm
                        </td>
                        <td>
                            520px
                        </td>
                    </tr>
                </tbody>
            </table>
            <input (change)="updateImage($event)" type="file" accept="image/png">
        </div>
        <div *ngIf="isType('10')" class="col-md-12 element-preview" id="insert">
            <label for="callbitmap-font" class="col-md-12 text-center">
                <span>{{'Font' | translate}}:</span>
            </label>
            <select (change)="changeCallBitmapFont($event)" ng-model="callBitmapFont" name="font" id="callbitmap-font">
                <option value="roboto">Roboto</option>
                <option value="jellee">Jellee</option>
                <option value="free">Free Monospace</option>
            </select>
        </div>
        <div *ngIf="isType('10')" class="col-md-12 element-preview" id="insert">
            <label for="callbitmap-inverted" class="col-md-12 text-center">
                <span>{{'Inverted' | translate}}:</span>
            </label>
            <input (change)="changeCallBitmapInverted($event)" ng-model="callBitmapInverted" type="checkbox"
                id="callbitmap-inverted" name="inverted" value="1">
        </div>
        <div *ngIf="isType('13')" class="col-md-12 element-preview" id="insert">
            <label for="callbitmap-inverted" class="col-md-12 text-center">
                <span>{{'Waiting Label' | translate}}:</span>
            </label>
            <input (keyup)="changeWaitingLabel($event)" ng-model="waitinglabel" type="text"
                id="ammountofpeople-waitinglabel" name="waitinglabel" value="Nº de pessoas em espera:">
        </div>
        <div *ngIf="isType('8')" class="col-md-12 element-preview" id="insert">
            <label for="callbitmap-inverted" class="col-md-12 text-center">
                <span>{{'Wait time Label' | translate}}:</span>
            </label>
            <input (keyup)="changeWaitTimeLabel($event)" ng-model="waitTimeLabel" type="text"
                id="waittime-label" name="waitTimeLabel" value="Tempo de espera:">
        </div>
        <div *ngIf="isType('3')" class="col-md-12 element-preview">
            <label class="col-md-12 text-center">
                <span>{{'Insert text' | translate}}:</span>
            </label>
            <input (blur)="updateText($event)" style="width: 100%;" value="{{optionValue}}" type="text">
        </div>
        <div *ngIf="isType('12')" class="col-md-12 element-preview">
            <label class="col-md-12 text-center">
                <span>{{'Insert text' | translate}}:</span>
            </label>
            <input (blur)="updateText($event)" style="width: 100%;" value="{{optionValue}}" type="text">
        </div>
        <label class="col-md-12 text-center">
            <span>{{'Example' | translate}}:</span>
        </label>
        <div *ngIf="isPreview()" class="col-md-12 pull-right element-preview">
            <call *ngIf="isType('4')"></call>
            <room *ngIf="isType('5')"></room>
            <barcode class="ticket-image-editor" *ngIf="isType('6')"></barcode>
            <service *ngIf="isType('7')"></service>
            <service-group *ngIf="isType('16')"></service-group>
            <timestamp *ngIf="isType('9')"></timestamp>
            <separator *ngIf="isType('1')"></separator>
            <textblock *ngIf="isType('11')"></textblock>
            <appointment *ngIf="isType('14')"></appointment>
            <clientname *ngIf="isType('15')"></clientname>
            <callbitmap [fontFamily]="callBitmapFont" [inverted]="callBitmapInverted" *ngIf="isType('10')"></callbitmap>
            <amount-of-people [waitinglabel]="waitinglabel" *ngIf="isType('13')"></amount-of-people>
            <waittime [waitTimeLabel]="waitTimeLabel" *ngIf="isType('8')"></waittime>
            <qrcode-tag *ngIf="isType('12')" [qrcodeContent]="callBitmapFont"></qrcode-tag>
            <p *ngIf="isType('3')">{{optionValue}}</p>
            <img class="ticket-image-editor" *ngIf="isType('2')" src="{{optionValue}}" />
        </div>
        <div class="form-group">
            <a type="button" *ngIf="isEditable()" class="btn btn-danger pull-right" (click)="removeItem()">{{'Remove' | translate}}</a>
            <button type="button" class="btn btn-primary" (click)="addToStack()">Ok</button>
        </div>
    </div>
</ng-template>