<h2>{{Model.modelAliasSingular}}</h2>

<div id="scheduling-group-form">
  <form *ngIf="formModel" [formGroup]="formModel" (submit)="submitForm($event)">
    <div *ngFor="let param of params" class="col-md-12 form-group" [ngClass]="{'hidden': param.type=='hidden'}">
      <input-attribute (agencyFilterEmitter)="agencyFilter($event)" *ngIf="paramIsValid(param)" [error]="getError(param.attr)"
        [record]="record" [formGroup]="formModel" [Model]="Model" [param]="param" [relations]="relationalModelsRelations"></input-attribute>
    </div>

    <div class="col-md-12 form-group" *ngIf="this.record || !this.recordId">
      <label for="simultaneous" class="col-md-12">
        <span [ngClass]="{'error-label': getError('simultaneous')}">{{'Simultaneous' | translate}}</span> <tooltip-component [sentence]="tooltips.simultaneous"></tooltip-component>
        <p *ngIf="getError('simultaneous')" Class="error-label"><small>{{ getError('simultaneous') }}</small></p>
        <input type="number" name="simultaneous" class="form-control" min="0" formControlName="simultaneous">
      </label>
    </div>

    <div class="col-md-12 form-group" *ngIf="this.record || !this.recordId">
      <label for="days_ahead" class="col-md-12">
        <span [ngClass]="{'error-label': getError('days_ahead')}">{{'Days Ahead' | translate}}</span> <tooltip-component [sentence]="tooltips.days_ahead"></tooltip-component>
        <p *ngIf="getError('days_ahead')" Class="error-label"><small>{{ getError('days_ahead') }}</small></p>
        <input type="number" name="days_ahead" class="form-control" min="0" formControlName="days_ahead">
      </label>
    </div>

    <div class="col-md-12 form-group" *ngIf="this.record || !this.recordId">
      <label for="duration" class="col-md-12">
        <span [ngClass]="{'error-label': getError('duration')}">{{'Duration' | translate}}</span> <tooltip-component [sentence]="tooltips.duration"></tooltip-component>
        <p *ngIf="getError('duration')" Class="error-label"><small>{{ getError('duration') }}</small></p>
        <input type="number" name="duration" class="form-control" min="0" formControlName="duration">
      </label>
    </div>

    <div class="col-md-12 form-group" *ngIf="this.record || !this.recordId">
      <label for="weekdays" class="col-md-12">
        <span [ngClass]="{'error-label': getError('weekdays')}">{{'Weekdays' | translate}}</span> <tooltip-component [sentence]="tooltips.weekdays"></tooltip-component>
        <p *ngIf="getError('weekdays')" Class="error-label"><small>{{ getError('weekdays') }}</small></p>
        <div [formGroup]="weekdayTypes">
          <select formControlName="type" class="form-control">
            <option value="0">Todos os dias</option>
            <option value="1">Cada dia</option>
          </select>
        </div>
        <div class="weekdays-container">
          <weekday *ngIf="weekdayTypes.controls.type.value == 0" [formGroup]="weekdaysAll" label="Todos os dias"></weekday>
        </div>
        <div class="weekdays-container" *ngIf="weekdayTypes.controls.type.value == 1">
          <weekday *ngFor="let formGroup of weekdays; let i = index" [formGroup]="formGroup" [label]="weekdaysLabels[i]"></weekday>
        </div>
      </label>
    </div>

    <div class="col-md-12">
      <div class="form-group">
        <div class="form-btn-padding pull-left">
          <button class="btn btn-success" type="submit">{{'Save' | translate}}</button>
        </div>
        <div class="form-btn-padding pull-left">
          <a routerLink="/schedulinggroups" class="btn btn-danger">{{'Cancel' | translate}}</a>
        </div>
      </div>
    </div>

  </form>
</div>