<div #styleContainer></div>
<div class="kiosk-identification-input-title">
    {{ getValue('identificationinput-message') }}

</div>
<div class="kiosk-identification-input-keyboard-container">

    <div class="numeric-keyboard-container">
        <p class="keyboard-title">{{ getValue('identificationinput-keyboardtitle') }}</p>

        <input class="keyboard-input" type="text" name="mobile" value='12345'>


        <div class="keyboard-row">
            <div class="key">1</div>
            <div class="key">2</div>
            <div class="key">3</div>
        </div>

        <div class="keyboard-row">
            <div class="key">4</div>
            <div class="key">5</div>
            <div class="key">6</div>
        </div>

        <div class="keyboard-row">
            <div class="key">7</div>
            <div class="key">8</div>
            <div class="key">9</div>
        </div>

        <div class="keyboard-row">
            <div class="key delete-button" value="back"> <i class="fa fa-times"></i> </div>
            <div class="key">0</div>
            <div class="key confirm-button" value="go"><i class="fa fa-angle-right"></i></div>
        </div>
    </div>
</div>
<div  class="kiosk-button no-identification">
    {{ getValue('identificationinput-no_id_button') }}
</div>