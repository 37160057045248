<div class="login">
  <div class="logo">
      <img class="logo-img" src="/assets/images/way2queuemanagement_logo.svg" alt="">
  </div>
    <div class="panel panel-default login-panel">
      <div class="panel-body">
        <spinner *ngIf="isLoading" class="spinner" [size]="70" [tickness]="5" color="{{environment.layout.bgrColor}}"></spinner>
        <div class="alert" *ngIf="!isLoading"><i>Não foi possível fazer login, por favor contacte o fornecedor do serviço</i></div>
      </div>
    </div>
    <div class="col-xs-12"></div>
</div>