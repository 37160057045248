<div #styleContainer></div>
        <div class="kiosk-confirm-appointment-container">
            <div class="confirm-app-title">{{ getValue('confirmappointment-message') }}</div>
            <div class="alphanumeric-keyboard-container">
                <p class="confirm-appointment-keyboard-title">{{ getValue('confirmappointment-keyboardtitle') }}</p>

                <input class="input-appointment-code" type="text" name="mobile" value='12345'>


                <div class="alphanumeric-key-row odd">
                    <div class="alphanumeric-key">1</div>
                    <div class="alphanumeric-key">2</div>
                    <div class="alphanumeric-key">3</div>
                    <div class="alphanumeric-key">4</div>
                    <div class="alphanumeric-key">5</div>
                    <div class="alphanumeric-key">6</div>
                    <div class="alphanumeric-key">7</div>
                    <div class="alphanumeric-key">8</div>
                    <div class="alphanumeric-key">9</div>
                    <div class="alphanumeric-key">0</div>
                </div>

                <div class="alphanumeric-key-row even">
                    <div class="alphanumeric-key">Q</div>
                    <div class="alphanumeric-key">W</div>
                    <div class="alphanumeric-key">E</div>
                    <div class="alphanumeric-key">R</div>
                    <div class="alphanumeric-key">T</div>
                    <div class="alphanumeric-key">Y</div>
                    <div class="alphanumeric-key">U</div>
                    <div class="alphanumeric-key">I</div>
                    <div class="alphanumeric-key">O</div>
                    <div class="alphanumeric-key">P</div>
                </div>

                <div class="alphanumeric-key-row odd">
                    <div class="alphanumeric-key">A</div>
                    <div class="alphanumeric-key">S</div>
                    <div class="alphanumeric-key">D</div>
                    <div class="alphanumeric-key">F</div>
                    <div class="alphanumeric-key">G</div>
                    <div class="alphanumeric-key">H</div>
                    <div class="alphanumeric-key">J</div>
                    <div class="alphanumeric-key">K</div>
                    <div class="alphanumeric-key">L</div>
                    <div class="alphanumeric-key">Ç</div>
                </div>

                <div class="alphanumeric-key-row even">
                    <div class="alphanumeric-key">Z</div>
                    <div class="alphanumeric-key">X</div>
                    <div class="alphanumeric-key">C</div>
                    <div class="alphanumeric-key">V</div>
                    <div class="alphanumeric-key">B</div>
                    <div class="alphanumeric-key">N</div>
                    <div class="alphanumeric-key">M</div>
                </div>
                <div class="alphanumeric-key-row odd">
                    <div class="alphanumeric-key delete-button" value="back"> <i class="fa fa-times"></i> </div>
                    <div class="alphanumeric-key confirm-button" value="go"><i class="fa fa-angle-right"></i></div>
                </div>
            </div>
        </div>
