<div #styleContainer></div>
        <div id="kiosk-thanks-sms-screen">
            <div id="kiosk-thankyou-sms-message">
                <span *ngIf="hasValue('has_client_identification')" class="client-name-bye-msg">
                    {{ getValue('endscreensms-client_message_prefix') }} António
                </span>
                <span *ngIf="hasValue('has_client_identification')" class="custom-bye-msg-client">
                    Desejamos-lhe um bom dia
                </span>
                <span class="custom-bye-msg">
                    {{ getValue('endscreensms-no_client_message') }}
                </span>
                <span class="default-bye-msg"> {{ getValue('endscreensms-message') }}</span>
            </div>
            <div id="kiosk-sms-end-image-container"
                [style.background-image]="images['endscreensms-image'] ? 'url('+images['endscreensms-image']+')' : ''">
                <div *ngIf="hasValue('display_waiting_zone_info')" class="waiting-zone-container">
                    <div class="waiting-zone-image-container" [ngStyle]="{'background-image':  'url(assets/images/waiting-zone.png)'}">
                    </div>
                    <div class="waiting-zone-message" [innerHTML]="'Por favor dirija-se a zona de espera designada'">
                    </div>
                </div>
            </div>
        </div>
