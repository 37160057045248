<form [formGroup]="form">
    <div class="form-group col-md-12 row">
        <div class="col-md-3">
            <label>{{'Ticket' | translate}}</label>
            <input class="form-control ticket" type="text" value="" placeholder="A01" formControlName="ticket">
        </div>
        <div class="col-md-3">
            <label>{{'Date' | translate}}</label>
            <input type="text" placeholder="Datepicker" formControlName="date" class="form-control" bsDatepicker [bsValue]="bsValue" value="{{formatDate()}}" [bsConfig]="{ isAnimated: true, dateInputFormat: 'YYYY-MM-DD', containerClass:'theme-blue' }" >
        </div>
        <div class="col-md-3">
            <label
                title="{{'Order that the tickets were inserted in the system' | translate}}">{{'Insertion Order' | translate}}</label>
            <select id="date-from" class="form-control" formControlName="sortBy">
                <option value="">{{'Choose an option' | translate}}</option>
                <option value="1">{{'Ascending' | translate}}</option>
                <option value="-1">{{'Descending' | translate}}</option>
            </select>
        </div>
        <div class="col-md-3">
            <label title="{{'Desired number of results' | translate}}">{{'Number of Results' | translate}}</label>
            <select id="date-from" class="form-control" formControlName="nCall">
                <option value="">{{'Choose an option' | translate}}</option>
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
            </select>
        </div>
    </div>
    <div class="form-group col-md-12 row" *ngIf="('listServices' | hasPermission ) || ('listServiceGroups' | hasPermission )">
        <div class="col-md-3" *ngIf="('listServiceGroups' | hasPermission )">
            <label title="{{'Service Group' | translate}}">{{'Service Group' | translate}}</label>
            <select id="date-from" class="form-control" formControlName="service_group_id" (change)="resetServiceId()">
                <option value="">{{'Choose an option' | translate}}</option>
                <option *ngFor="let serviceGroup of serviceGroups" value="{{serviceGroup.id}}">{{serviceGroup.name}}</option>
            </select>
        </div>
        <div class="col-md-3" *ngIf="('listServices' | hasPermission )">
            <label title="{{'Service' | translate}}">{{'Service' | translate}}</label>
            <select id="date-from" class="form-control" formControlName="service_id">
                <option value="">{{'Choose an option' | translate}}</option>
                <option *ngFor="let service of filteredServices" value="{{service.id}}">{{service.name}}</option>
            </select>
        </div>
    </div>
</form>
