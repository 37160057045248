<a *ngIf="('userTasks' | hasPermission )" title="Acções" class="list-option-endpoint fa fa-cogs"
    (click)="openActionsModal(template)"></a>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'Actions' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="endpoints">{{'Task' | translate}}</label>
            <select name="endpoints" id="endpoints" [(ngModel)]="currentAction" class="form-control">
                <option value="">{{'Choose an option' | translate}}</option>
                <option *ngFor="let task of display_tasks;let i = index" value="{{task.id}}">
                    {{task.name}}
                </option>
            </select>
        </div>
        <div class="form-group">
            <div class="form-group">
                <a class="btn btn-primary" (click)="requestAction()">Ok</a>
            </div>
        </div>
    </div>
</ng-template>