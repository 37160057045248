<div #styleContainer></div>
{{getStyle()}}
<h2>{{title}}</h2>
<div *ngIf="init" class="col-md-12 form-group">
    <div class="col-md-12 form-group">
        <div class="col-md-5 form-group">
            <label for="">
                {{translateTerm('Screen height')}}
            </label>
            <input type="text" class="form-control" name="screenHeight" id="" [(ngModel)]="screenHeight"
            (change)="changeScale(scaleT);changeHeightCallBack();" (keyup)="changeScale(scaleT);changeWidthCallBack();">
        </div>
        <div class="col-md-5 form-group">
            <label for="">
                {{translateTerm('Screen width')}}
            </label>
            <input type="text" class="form-control" name="screenWidth" id="" [(ngModel)]="screenWidth"
                (change)="changeScale(scaleT);changeWidthCallBack();" (keyup)="changeScale(scaleT);changeWidthCallBack();">
        </div>
        <div *ngFor="let config of configs" class="col-md-5 form-group">
            <label for="">
                {{translateTerm(config.label)}}
            </label>
            <input *ngIf="config.input_type == 'text'" type="{{config.input_type}}" class="form-control" name="{{config.id}}"
                [(ngModel)]="inquiry_configs[config.id].value" (change)="updateInput($event,config.id,config.input_type)">
            <input *ngIf="config.input_type == 'file'" type="{{config.input_type}}" class="form-control" name="{{config.id}}"
                (change)="updateInput($event,config.id,config.input_type)">
            <button class="btn btn-sm btn-danger form-group" style="margin-top: 5px;" *ngIf="config.input_type == 'file' && inquiry_configs[config.id].value && inquiry_configs[config.id].value != '0' " (click)="removeImage(config.id)">
                {{translateTerm('Remove')}} <i class="glyphicon glyphicon-trash"></i>
            </button>
        </div>
    </div>
    <div class="col-md-12 form-group">
        <div class="col-md-4">
            <div class="form-group">
                <label for="" class="col-md-12">
                    {{translateTerm('Css File')}}
                </label>
                    <textarea class="form-control" name="cssFile" id="" rows="30" [(ngModel)]="cssContent"></textarea>
                    <a class="btn btn-info" style="margin-top: 5px;" (click)="resetCss()">Reset Css</a>
    
            </div>
        </div>
        <div class="col-md-8">
            <div class="slidecontainer col-md-6">
                <label for="">{{translateTerm('Scale')}} : {{scaleT}}</label>
                <input type="range" min="0" max="100" value="60" class="slider" id="myRange"
                    (change)="changeScaleEL($event)">
            </div>
            <div *ngIf="inquiry" class="col-md-12">
                <label for="">Preview</label>
                <tabset>
                    <tab heading="Ecrã inicial">
                        <inquiry-start-screen [cssContent]="cssContent" [images]="images" [configs]="updatedConfigs" [inquiry]="inquiry"></inquiry-start-screen>
                    </tab>
                    <tab heading="Ecrã de procura de senha">
                        <inquiry-ticket-search-screen [cssContent]="cssContent" [images]="images" [configs]="updatedConfigs" [inquiry]="inquiry"></inquiry-ticket-search-screen>
                    </tab>
                    <tab heading="Ecrã final">
                        <inquiry-thanks-screen [cssContent]="cssContent" [images]="images" [configs]="updatedConfigs" [inquiry]="inquiry"></inquiry-thanks-screen>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12 form-group">
    <div class="form-btn-padding pull-left">
        <a type="button" class="btn btn-danger" (click)="goBack()">{{translateTerm('Cancel')}}</a>
    </div>
    <div class="form-btn-padding pull-left">
        <a type="button" class="btn btn-success" (click)="submitForm()">{{translateTerm('Save')}}</a>
    </div>
</div>