<div #styleContainer></div>

<div class="ticket-screen-message">
  <div class="ticket-screen-icon">
    <div class="ticket-screen-icon-rect">
    </div>
    <div class="ticket-screen-info-number">
      <span class="ticket-screen-info-number-label">A001</span>
    </div>
  </div>
</div>