<div class="top-user-bar"
     [style.background]="w4mStyle.bgrColor">
  <div class="logo">
    <img src="/assets/images/{{w4mStyle.logo}}" alt="Way 2 Queue Managment">
  </div>
  <div class="user-info">
    <div class="welcome-user">
      {{translateTerm('Welcome')}} {{getUserName()}}
    </div>
    <!-- notifications -->
    <notifications></notifications>

    <!-- end notifications -->
    <div  id="user-name" class="profile-btn fa fa-user" (click)="getSelfEditUrl()"></div>
    <div (click)="logOut()" class="logout-btn fa fa-power-off"></div>
  </div>
</div>
<div class="top-company-bar"
     [style.borderBottom]="'6px solid' + clientSettings.dividerColor"
     [style.background]="clientSettings.companyBarColor">
  <div class="logo pull-left">
    <img [src]="clientSettings.logo">
  </div>
  <div class="client-selector pull-right" *ngIf="clients.length > 1">
      <select class="client-dropdown" (change)="setClientId($event)" [(ngModel)]="clientId">
          <option *ngFor="let item of clients" value="{{item.id}}">
            {{item.name}}
          </option>
      </select>
  </div>
</div>
