<breadcrumb [routeBreadcrumb]="breadcrumb"></breadcrumb>


<div class="col-md-12" class="positionHeader" style="display: flex">

  <div class="col-md-4">
    <label>{{ "Agencies" | translate }}</label>

    <angular4-multiselect
      *ngIf="agencies"
      [data]="agencies"
      [(ngModel)]="selectedItems"
      [settings]="dropdownSettings"
      (onSelect)="onItemSelect($event)"
      (onDeSelect)="onItemDeSelect($event)"
    >
      <c-item>
        <ng-template let-item="item">
          <label>{{item.identifier}} - {{item.name}}</label>
        </ng-template>
      </c-item>

    </angular4-multiselect>
  </div>

  <div class="col-md-4">
    <form [formGroup]="form">
        <label>{{ "Date" | translate }}</label>
        <input type="text" placeholder="Datepicker" id="datepicker" formControlName="date" class="form-control" bsDatepicker [bsValue]="bsValue" value="{{ getDateToFormat() }}" [bsConfig]="{ isAnimated: true, dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }" />
    </form>
  </div>

</div>

<div class="col-md-12">
    <div id="calendar"></div>
</div>


<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-sm">
        <div *ngIf="getSchedule(schedule)" class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title pull-left">{{getSchedule(schedule).service.name}}</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="this.modal.hide();">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="modal-dialog modal-dialog-scrollable">
                <div *ngIf="getSchedule(schedule)">
                  <div class="form-group">
                    <label>{{'Service' | translate}}</label>
                    <p>{{getSchedule(schedule).service.name}}</p>
                  </div>
                  <div class="form-group">
                    <label>{{'Start Date' | translate}}</label>
                    <p>{{formatDateHours(getSchedule(schedule).start)}}</p>
                  </div>
                  <div class="form-group">
                    <label>{{'End Date' | translate}}</label>
                    <p>{{formatDateHours(getSchedule(schedule).end)}}</p>
                  </div>

                  <div *ngIf="getSchedule(schedule).user">
                    <div class="form-group" >
                      <label>{{'User' | translate}}</label>
                      <p>{{getSchedule(schedule).user.name}}</p>
                    </div>
                    <div class="form-group" >
                      <label>{{'User Code' | translate}}</label>
                      <p>{{getSchedule(schedule).user.user_code}}</p>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="getSchedule(schedule).user_clients">
                    <label>{{'User Group' | translate}}</label>
                    <p>{{getNameUsergroup((getSchedule(schedule).user_clients.usergroup_id)).name}}</p>
                  </div>

                  <div class="form-group" *ngIf="getSchedule(schedule).status">
                    <label>{{'Schedule Status' | translate}}</label>
                    <p>{{getSchedule(schedule).status.description | translate}}</p>
                  </div>
                  <div class="form-group" *ngIf="getSchedule(schedule).rule && acl">
                    <label>{{'Schedule Rule' | translate}}</label>&nbsp;
                    <a (click)="openRuleNewTab(getSchedule(schedule).rule.scheduling_group_id)" class="fa fa-eye"></a>
                  </div>

                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-primary pull-right" aria-label="Close" (click)="this.modal.hide();">
                Close
            </button>
            </div>

        </div>
    </div>
</div>