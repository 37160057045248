<div class="form-element-spacing checkbox-list-container" [formGroup]="formGroup">
    <span *ngIf="!hideLabel" [ngClass]="{'error-label': error}" class="label-checkbox-list">{{param.alias | translate }} <tooltip-component [sentence]="param.tooltip"></tooltip-component> </span>
    <note *ngIf="!hideLabel" [text]="getNote()"></note>
    <p *ngIf="error" [ngClass]="{'error-label': error}"><small [innerHTML]="error"></small></p>
    <div>
        <angular4-multiselect
            [data]="dropdownList"
            [(ngModel)]="checked"
            [settings]="dropdownSettings"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="OnItemDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)"
            [ngModelOptions]="{standalone: true}"
        >
        </angular4-multiselect>
    </div>
</div>