<label for="{{identifier}}" class="col-md-12" [formGroup]="formGroup">
  <span [ngClass]="{'error-label': error}">{{ translateTerm(param.alias) }}</span> <tooltip-component [sentence]="param.tooltip"></tooltip-component>
  <note [text]="param.note"></note>
  <p *ngIf="error" [ngClass]="{'error-label': error}"><small [innerHTML]="error"></small></p>
  <input type="hidden" class="acl" [formControlName]="param.attr">
  <span >
    <label class="col-md-12" *ngFor="let parentObject of items; let i = index;" for="{{identifier}}-{{param.attr}}">
        <h4> {{translateTerm(parentObject.parent)}}</h4>
        <div *ngFor="let item of parentObject.items">
            <input type="checkbox" (change)="setChecked($event, item.index)" class="acl-{{identifier}}-checkbox" id='acl-{{identifier}}-checkbox-{{item.index}}' name="{{param.attr}}[]">
            <label for='acl-{{identifier}}-checkbox-{{item.index}}'>
                {{ translateTerm(item.alias) }}
            </label>
            {{setValue(item.index)}}
        </div>
    </label>
  </span>
</label>
