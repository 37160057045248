<ng-container *ngFor="let item of itemList; let i = index;">
    <div class="shell-container">
        <div class="shell-header" [ngClass]="{'not-expanded': isExpand != i, 'error-task':item.taskstatus_id == 4,'not-finished': !hasStatusToExpand(item)}" (click)="hasStatusToExpand(item) && isExpand != i ? isExpand = i : isExpand = null">
            > {{item.params}} 
            <div class="expander">
                {{item.created_at | convertToTimezone}} 
                <i *ngIf="hasStatusToExpand(item) && isExpand != i">+</i>
                <i *ngIf="hasStatusToExpand(item) && isExpand == i">-</i>
            </div>
        </div>
        <div *ngIf="isExpand == i">
            <pre>{{item.message}}</pre>
            <div class="shell-footer">
                {{getDuration(item)}}
            </div>
        </div>
    </div>

</ng-container>


<pagination-component [currentPage]="currentPage" [lastPage]="lastPage"
    (onPageChage)="changePage($event)"></pagination-component>