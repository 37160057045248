<div class="password-update">
      <div class="panel panel-default password-update-panel">
        <div class="panel-header">
          <div class="panel-heading password-update-title">
            <div class="col-xs-12">
                <span class="glyphicon glyphicon-lock"></span> {{ 'Change your password to continue' | translate}}
            </div>
          </div>
        </div>
        <div class="panel-body">
          <form class="password-update-form"(submit)="updatePassword($event)">
                <div class="alert alert-danger" *ngIf="hasErrors()" role="alert">
                    <ul>
                        <li *ngFor="let error of getErrors()" [innerHTML]="error"></li>
                    </ul>
                </div>
              <div class="form-group"><br>
                <label for="">{{translateTerm('New Password')}}</label>
                <input type="password" id="loginPassword" class="form-control" (keyup)="updatePasswordValue($event)" >
              </div>
              <div class="form-group"><br>
                <label for="">{{translateTerm('Confirm Password')}}</label>
                <input type="password" id="loginPassword" class="form-control" (keyup)="updatePasswordRepeatValue($event)">
              </div>
              <div class="form-group"><br>
                <button  type="submit" class="btn btn-submit btn-default pull-right">{{translateTerm('Submit')}}</button>
              </div>
          </form>
        </div>
      </div>
  </div>