<div class="login">
    <div class="logo">
        <img class="logo-img" src="/assets/images/way2queuemanagement_logo.svg" alt="">
    </div>
      <div class="panel panel-default login-panel">
        <div class="panel-header">
            <div class="panel-heading login-title">
              <div class="col-xs-12"><span class="glyphicon glyphicon-lock"></span> {{'Password reset' | translate}}</div>
            </div>
          </div>
        <div class="panel-body">
        <div class="col-xs-12 alert alert-danger" *ngIf="hasErrors()" role="alert">
            <ul>
                <li>{{error | translate}}</li>
            </ul>
        </div>
          <div class="col-xs-12">
            <span *ngIf="confirmed">{{'Please check your email'|translate}}.</span>
            <span *ngIf="!confirmed">{{'Please enter your email if there is a matching account you will recieve a email with the reset instructions' | translate}}.</span>
          </div>
          <form  *ngIf="!confirmed" class="password-reset-form"(submit)="requestReset($event)">
            <div class="col-xs-12"><br>
              <label for="">{{'Email' | translate}}</label>
              <input type="email" id="loginPassword" name="email" class="form-control" [(ngModel)]="email" >
            </div>
            <div class="col-xs-12"><br>
              <button  type="submit" class="btn btn-submit btn-default pull-right">{{'Submit' | translate}}</button>
              <button  (click)="login()" class="btn btn-submit btn-default">{{'Back' | translate}}</button>
            </div>
        </form>
        <div *ngIf="confirmed" class="col-xs-12"><br>
          <button  (click)="login()" class="btn btn-submit btn-default">{{'Back' | translate}}</button>
        </div>
        </div>
      </div>
      <div class="col-xs-12"></div>
  </div>