<breadcrumb  [routeBreadcrumb]="breadcrumbArray"></breadcrumb>
<h2>{{'Usergroup configs' | translate}} {{'of' | translate}} {{getTitle()}}</h2>
<div *ngIf="errors && getAllErrors().length" class="alert alert-danger col-md-6 col-md-offset-3">
    <ul>
        <li *ngFor="let errorMsg of getAllErrors()">
            {{ errorMsg | translate }}
        </li>
    </ul>
</div>
<div class="table-responsive main-container col-md-12">
<table class="table table-striped">
    <thead>
        <tr>
            <th>{{ 'Usergroup' | translate}}</th>
            <th style="text-align: center;">
                <input
                    type="checkbox"
                    id="selectAll_usergroups"
                    (change)="selectAll($event, 'usergroups')"
                    [checked]="isAllSelected('usergroups')"
                    [disabled]="readOnly"
                />
                {{'Usergroups' | translate}}
                <tooltip-component [sentence]="getTooltip('usergroups')"></tooltip-component>
            </th>
            <th style="text-align: center;">
                <input
                    type="checkbox"
                    id="selectAll_target_usergroups"
                    (change)="selectAll($event, 'target_usergroups')"
                    [checked]="isAllSelected('target_usergroups')"
                    [disabled]="readOnly"
                />
                {{'Target Usergroups' | translate}}
                <tooltip-component [sentence]="getTooltip('target_usergroups')"></tooltip-component>
            </th>
            <th style="text-align: center;">
                <input
                    type="checkbox"
                    id="selectAll_disabled"
                    (change)="selectAll($event, 'disabled')"
                    [checked]="isAllSelected('disabled')"
                    [disabled]="readOnly"
                />
                {{'Disabled' | translate}}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let usergroup of usergroups">
            <td>{{usergroup.name}}</td>
            <td style="text-align: center;">
                <input
                    type="checkbox"
                    [id]="usergroup.id + '_usergroups'"
                    [name]="usergroup.id + '_usergroups'"
                    value="usergroups"
                    [checked]="isChecked(usergroup.id, 'usergroups')"
                    (change)="controlCheckbox($event)"
                    [disabled]="readOnly"
                />
            </td>
            <td style="text-align: center;">
                <input
                    type="checkbox"
                    [id]="usergroup.id + '_target_usergroups'"
                    [name]="usergroup.id + '_target_usergroups'"
                    value="target_usergroups"
                    [checked]="isChecked(usergroup.id, 'target_usergroups')"
                    (change)="controlCheckbox($event)"
                    [disabled]="readOnly"
                />
            </td>

            <td style="text-align: center;">
                <input
                    type="checkbox"
                    [id]="usergroup.id + '_disabled'"
                    [name]="usergroup.id + '_disabled'"
                    value="disabled"
                    [checked]="isChecked(usergroup.id, 'disabled')"
                    (change)="controlCheckbox($event)"
                    [disabled]="readOnly"
                />
            </td>
        </tr>
    </tbody>
</table>
<div [hidden]="readOnly">
    <div class="form-btn-padding pull-left">
        <button class="btn btn-success" (click)="submitResult()">{{'Save' | translate}}</button>
    </div>
    <div class="form-btn-padding pull-left">
        <a routerLink="/usergroups" class="btn btn-danger">{{'Cancel' | translate}}</a>
    </div>
</div>
</div>
