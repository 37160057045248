<h2>{{'Definitions' | translate}} {{agencyName}}</h2>
<div class="configs-container" *ngIf="groups.length > 0">
  <form *ngIf="formGroupConfigs" (submit)="submit()" [formGroup]="formGroupConfigs">
      <accordion [showArrows]="true">
        <accordion-group *ngFor="let group of groups; let i = index;"  [isOpened]="i==0" heading="{{group | translate}}">
          <ul >
            <li *ngFor="let config of getConfigsByGroup(group); let i = index;" [ngClass]="{'dark': i % 2 != 0}">
              <div class="form-group group-horizontal">
                <div class="group-label">
                  <label>
                    {{config.label | translate}}
                    <tooltip-component *ngIf="config.description" [sentence]="config.description"></tooltip-component>
                    <b *ngIf="config.from_agency" class="text-danger" title="{{ 'This config overrides client setting' | translate}}">*</b>
                  </label>
                </div>
                <config-input [type]="config.type" [formGroupInstance]="formGroupConfigs.controls[group]" [formControlNameInstance]="config.param" [options]="config.options"></config-input>
              </div>
            </li>
          </ul>
        </accordion-group>
      </accordion>

      <div *ngIf="agencyMode">
        <button *ngIf="('editAgencyConfigs' | hasPermission)" class="btn btn-success" type="submit">{{'Save' | translate}}</button>
        <a *ngIf="('resetAgencyConfigs' | hasPermission )" class="btn btn-danger" (click)="resetConfigsToClientValues()"><i class="fa fa-trash"></i> {{'Reset' | translate}}</a>
      </div>

      <button *ngIf="!agencyMode && ('editClientConfigs' | hasPermission)" class="btn btn-success" type="submit">{{'Save' | translate}}</button>
  </form>
</div>

