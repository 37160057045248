<div #styleContainer></div>

{{ getStyle() }}

<div class="" id="simple-kiosk">
    <div id="kiosk-main-container" [style.transform]="'scale('+scaleT+')'" [style.background-image]="'url('+images['main-backgroundImage']+')'">
        <div *ngIf="!hideHeader" id="kiosk-header">
            <img *ngIf="images['header-logo']" id="kiosk-header-logo"[ngClass]="'kiosk-header-logo kiosk-header-logo-'+currentRoute"
                [src]="images['header-logo']">
            <div id="kiosk-header-text" [ngClass]="'header-message-'+currentRoute">
                {{ getValue('header-message') }}
            </div>
            <div [ngClass]="'datetime-container datetime-container-'+currentRoute">
                <div class="time-container">{{ hora }}</div> &nbsp; <div class="date-container">{{ data }}</div>
            </div>
            <div *ngIf="showBackButton()" class="header-element back-button">
                <div class="back-button-container">
                    <fa name="angle-left"></fa>
                    <div class="back-button-message">{{ getValue('backbutton-message') }}</div>
                </div>
            </div>
        </div>

        <div class="kiosk-content">
            <servicegroups-screen-page [scaleT]="scaleT" *ngIf="screen == 'serviceGroupsScreen'" [formGroup]='formGroup' [images]="images"></servicegroups-screen-page>

            <sms-screen-page [scaleT]="scaleT" *ngIf="screen == 'smsScreen'" [formGroup]='formGroup' [images]="images"></sms-screen-page>

            <thanks-screen-sms-page [scaleT]="scaleT" *ngIf="screen == 'thanksScreenSms'" [formGroup]='formGroup' [images]="images"></thanks-screen-sms-page>

            <ticket-output-selection-page [scaleT]="scaleT" *ngIf="screen == 'ticketOutputSelection'" [formGroup]='formGroup' [images]="images"></ticket-output-selection-page>

            <services-kiosk-page [scaleT]="scaleT" *ngIf="screen == 'servicesScreen'" [formGroup]='formGroup' [services]="services" [images]="images"></services-kiosk-page>

            <no-services-kiosk-page [scaleT]="scaleT" *ngIf="screen == 'noServices'" [formGroup]='formGroup' [images]="images"></no-services-kiosk-page>

            <priority-page [scaleT]="scaleT" *ngIf="screen == 'priorityPage'" [formGroup]='formGroup' [services]="services" [images]="images"></priority-page>

            <thanks-screen-page [scaleT]="scaleT" *ngIf="screen == 'thanksScreen'" [formGroup]='formGroup' [images]="images"></thanks-screen-page>

            <confirmation-page [scaleT]="scaleT" *ngIf="screen == 'confirmationPage'" [formGroup]='formGroup' [images]="images"></confirmation-page>

            <confirmation-alphanumeric-page [scaleT]="scaleT" *ngIf="screen == 'confirmationAlphanumeric'" [formGroup]='formGroup' [images]="images"></confirmation-alphanumeric-page>

            <error-page [scaleT]="scaleT" *ngIf="screen == 'errorPage'" [formGroup]='formGroup' [images]="images"></error-page>

            <identification-page [scaleT]="scaleT" *ngIf="screen == 'identificationPage'" [formGroup]='formGroup' [images]="images"></identification-page>

            <readcard-finish [scaleT]="scaleT" *ngIf="screen == 'readcardFinish'" [formGroup]='formGroup' [images]="images"></readcard-finish>

            <loading-page [scaleT]="scaleT" *ngIf="screen == 'loadingPage'" [formGroup]='formGroup' [images]="images"></loading-page>

            <ticket-screen-page [scaleT]="scaleT" *ngIf="screen == 'ticketScreen'" [formGroup]='formGroup' [images]="images"></ticket-screen-page>

            <identification-input [scaleT]="scaleT" *ngIf="screen == 'identificationInput'" [formGroup]='formGroup' [images]="images"></identification-input>

            <welcome-page [scaleT]="scaleT" *ngIf="screen == 'startPage'" [formGroup]='formGroup' [images]="images"></welcome-page>
        </div>

        <div *ngIf="!hideFooter" id="kiosk-translations-area" [ngClass]="'translations-area-'+currentRoute">
            <div id="kiosk-flags-container">
                <img *ngFor="let lang of languagesAvailable" [src]="'/assets/images/flags/' + lang.language_code + '.png'">
            </div>
        </div>

        <div *ngIf="!hideFooter" id="kiosk-footer" [ngClass]="'kiosk-footer-area-'+currentRoute" [style.background-image]="'url('+images['footer-image']+')'">
        </div>
    </div>
</div>
