<ng-container *ngFor="let log of logGroup; let i = index;">
    <div class="log-container">
        <div class="log-header" [ngClass]="{'not-expanded': isExpand != i}" (click)="isExpand != i ? isExpand = i : isExpand = null">
            > {{log.name}} 
            <div class="expander">
                <i *ngIf="isExpand != i">+</i>
                <i *ngIf="isExpand == i">-</i>
            </div>
        </div>
        <div *ngIf="isExpand == i">
            <pre>{{log.value}}</pre>
            <div class="log-footer">
            </div>
        </div>
    </div>

</ng-container>
