<label [formGroup]="formGroup" for="{{identifier}}" class="col-md-12">
    <span [ngClass]="{'error-label': error}">{{param.alias}}</span> <tooltip-component [sentence]="param.tooltip"></tooltip-component>
    <note [text]="param.note"></note>
    <div class="col-md-12">
        <img id="{{getPreviewId()}}" class="img-preview" *ngIf="showPreview()" src="{{imagePreview}}"/>
    </div>
    <p *ngIf="error" [ngClass]="{'error-label': error}"><small [innerHTML]="error"></small></p>
    <div *ngIf="isImage()" class="col-md-12" style="padding:0 !important; margin:0 0 5px 0 !important;">
        <div class="col-md-9">
            <input id="{{identifier}}" multiple (change)="showInfo($event)" [name]="param.attr" #fileInput  type="file" class="form-control" placeholder="{{param.alias}}">
            <input type="hidden" [formControlName]="param.attr">
        </div>
        <div class="col-md-2">
            <button type="button" class="btn btn-danger" (click)="removeImage(identifier)"><span class="glyphicon glyphicon-trash"></span></button>
        </div>
    </div>
    <div *ngIf="!isImage()" class="col-md-12" style="padding:0 !important; margin:0 0 5px 0 !important;">
        <input id="{{identifier}}" multiple (change)="showInfo($event)" [name]="param.attr" #fileInput  type="file" class="form-control" placeholder="{{param.alias}}">
        <input type="hidden" [formControlName]="param.attr">
    </div>
    <ul *ngIf="hasPreview()">
        <li *ngFor="let file of getFilesForPreview()">
            <a href="{{getFileLink(file)}}">{{getFileAlias(file)}}</a>
        </li>
     </ul>
</label>
