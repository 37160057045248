<h2>Lista de Templates</h2>
<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="text-center">{{translateTerm('Name')}}</th>
        <th class="text-center">{{translateTerm('Type')}}</th>
        <th class="text-center">{{translateTerm('Page')}}</th>
        <th class="text-center">{{translateTerm('Omission')}}</th>
        <th class="text-center">{{translateTerm('Selected')}}</th>
        <th><span class="fa fa-cog"></span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let template of templates">
        <td>{{template.name}}</td>
        <td>
          <span *ngIf="template.client_id">Público</span>
          <span *ngIf="!template.client_id">Privado</span>
        </td>
        <td>{{pagesService.getPageAlias(template.page_id)}}</td>
        <td *ngIf="validateAcl('setDefaultTemplate')">
          <span *ngIf="template.is_default" (click)="changeDefault(template)" class="check-symbol fa fa-check-circle"></span>
          <span *ngIf="!template.is_default" (click)="changeDefault(template)" class="uncheck-symbol fa fa-times-circle"></span>
        </td>
        <td *ngIf="!validateAcl('setDefaultTemplate')">
          <span *ngIf="template.is_default" class="fa fa-check-circle"></span>
          <span *ngIf="!template.is_default" class="fa fa-times-circle"></span>
        </td>
        <td>
          <span *ngIf="template.selected" (click)="changeSelection(template)" class="check-symbol fa fa-check-circle"></span>
          <span *ngIf="!template.selected" (click)="changeSelection(template)" class="uncheck-symbol fa fa-times-circle"></span>
        </td>
        <td >
          <a routerLink="/template/{{template.template_id}}" *ngIf="hasPermissionToEdit(template)" class="fa fa-eye"></a>
          <a *ngIf="!hasPermissionToEdit(template)" class="fa fa-lock"></a>
          <a class="fa fa-trash" (click)="remove_template(template)" *ngIf="hasPermissionToRemove(template)"></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="row">
  <div class="col-md-12">
      <a *ngIf="hasCreateAcl" routerLink="/template" class="btn btn-success">Criar Template</a>
  </div>
</div>
