<button class="btn btn-danger" (click)="openActionsModal(dateDiffModal)">{{item.label}}</button>


<ng-template #dateDiffModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{item.header}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="form-group">
        <label>Intervalo de datas</label>
        <small class="error-message" *ngIf="dateRangeError">{{dateRangeError}}</small>
        <input type="text"
           class="form-control"
           bsDaterangepicker
           [minDate]="item.minDate"
           [(ngModel)]="dateRange">
      </div>
      <div class="form-group">
        <label>Agencia</label>
        <small class="error-message" *ngIf="agencyIdError">{{agencyIdError}}</small>
        <select class="form-control" [(ngModel)]="agencyId">
          <option value="">{{translateTerm('Choose an option')}}</option>
          <option *ngFor="let agency of item.filterAgencies" value="{{agency.id}}">{{agency.name}}</option>
        </select>
      </div>
      <p class="alert-message">{{statusMessage}}</p>
      <div class="form-group">
          <button type="button" class="btn btn-primary" (click)="submit()">Ok</button>
      </div>
  </div>
</ng-template>
