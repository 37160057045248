<div #styleContainer></div>
        <div id="kiosk-buttons-container">
            <h1 class="navigation-breadcrumb">
                <ng-container> Grupo de serviço A <ng-container> > </ng-container> </ng-container><ng-container> Grupo de serviço B</ng-container>
            </h1>
            <div id="kiosk-main-container-title">
                {{ getValue('main-message') }}
            </div>
            <div *ngIf="!servicesToDisplay.length" id="kiosk-buttons-container-outer">
                <div id="kiosk-buttons-container-inner">
                    <div class="kiosk-button">
                        <div class="kiosk-button-identifier">
                            <div class="kiosk-button-itentifier-text">A</div>
                        </div>
                        <span class="kiosk-button-label ">Serviço</span>
                    </div>
                    <div class="kiosk-button service-out-of-time">
                        <div class="kiosk-button-watermark">
                    {{ getValue('noservices-watermark') }}
                    </div>
                        <div class="kiosk-button-identifier">
                            <div class="kiosk-button-itentifier-text">B</div>
                        </div>
                        <span>Serviço Fora de Horas</span>
                    </div>
                    <div class="kiosk-button">
                        <div class="kiosk-button-identifier">
                            <div class="kiosk-button-itentifier-text">C</div>
                        </div>
                        <span class="kiosk-button-label">Serviço</span>
                    </div>
                    <div *ngIf="hasValue('kiosk_uses_appointments')" class="kiosk-button appointment-kiosk-button">
                        <span class="kiosk-button-label appointment-kiosk-button-label">{{ getValue('appointments-message') }}</span>
                    </div>
                    <div class="kiosk-button priority-kiosk-button">
                        <div class="kiosk-button-identifier" id="button-identifier-priority-button">
                            <div class="kiosk-button-itentifier-text"></div>
                        </div>
                        <span>
                            Atendimento Prioritário
                            <p>(Decreto-Lei nº58/2 016 de 29 de Agosto) <span class="label-icons">1 2 3 4</span> </p>
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="servicesToDisplay.length" id="kiosk-buttons-container-outer">
                <div id="kiosk-buttons-container-inner">
                    <div *ngFor="let service of servicesToDisplay" class="kiosk-button">
                        <div class="kiosk-button-identifier">
                            <div class="kiosk-button-itentifier-text">{{service.identifier}}</div>
                        </div>
                        <span class="kiosk-button-label ">{{service.alias}}</span>
                    </div>

                    <div *ngIf="hasValue('kiosk_uses_appointments')" class="kiosk-button appointment-kiosk-button">
                        <span class="kiosk-button-label appointment-kiosk-button-label">{{ getValue('appointments-message') }}</span>
                    </div>
                    <div class="kiosk-button priority-kiosk-button">
                        <div class="kiosk-button-identifier" id="button-identifier-priority-button">
                            <div class="kiosk-button-itentifier-text"></div>
                        </div>
                        <span>
                            Atendimento Prioritário
                            <p>(Decreto-Lei nº58/2 016 de 29 de Agosto) <span class="label-icons">1 2 3 4</span> </p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
