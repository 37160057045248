<div class="form-group">

    <div class="col-md-6">
        <label for="name">{{translateTerm("Elements")}}</label>
        <select class="form-control" id="power" required [(ngModel)]="currentElement">
            <option [value]="null">{{translateTerm('Choose an option')}}</option>
            <option *ngFor="let element of smsElements" [ngValue]="element">{{translateTerm(element.name)}}</option>
        </select>
    </div>
    <div *ngIf="currentElement && currentElement.input" class="col-md-4 form-group">
        <label for="name">{{translateTerm("Value")}}</label>
        <input type="text" class="form-control" required [(ngModel)]="currentElement.value">
    </div>
    <div class="col-md-2 form-group flex-form">
        <label for="name">{{translateTerm("Add")}}</label>
        <button class="btn btn-primary" (click)="addToTemplate()">+</button>
    </div>
</div>
<div class="form-group col-md-5">
    <div class="preview-template-container">
        <label for="name">Preview {{lang.alias}}</label>
        <div class="preview-template text-message-preview" [innerHTML]="getPreview()">
        </div>
    </div>
</div>
<div class="form-group col-md-7">
    <div class="preview-template-container">
        <label for="name">Template {{lang.alias}}</label>
        <p *ngIf="errorDict.content" [ngClass]="{'error-label': errorDict.content}"><small [innerHTML]="errorDict.content"></small></p>
        <textarea rows="10" [(ngModel)]='content.content'></textarea>
    </div>
</div>