<div class="login">
    <div class="logo">
        <img class="logo-img" src="/assets/images/way2queuemanagement_logo.svg" alt="">
    </div>
      <div class="panel panel-default login-panel">
        <div class="panel-header">
            <div class="panel-heading login-title">
              <div class="col-xs-12"><span class="glyphicon glyphicon-lock"></span> {{'Email confirmation' | translate}}</div>
            </div>
          </div>
        <div class="panel-body">
            <div class="alert alert-success" role="alert" *ngIf="confirmed">
                <ul>
                    <li>
                        {{'Email confirmation done successfully' | translate}}
                    </li>
                </ul>
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="!confirmed">
                <ul>
                    <li>
                        {{'Email confirmation failed' | translate}}
                    </li>
                </ul>
            </div>
          <button class="btn btn-submit btn-default" (click)="login()">Login</button>
        </div>
      </div>
      <div class="col-xs-12"></div>
  </div>
