<table class="table table-striped">
    <thead>
        <tr>
            <th>{{'Origin' | translate}}</th>
            <th>{{'Type'| translate}}</th>
            <th>{{'Value' | translate}}</th>
            <th>{{'Generation Date' | translate}}</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="haveTransaction(transactionArray); then haveTransactions; else notHaveTransactions"></ng-container>

        <ng-template #haveTransactions>
            <tr *ngFor="let transaction of transactionArray;">
                <td>{{ transaction.origin | translate}}</td>
                <td>{{ transaction.type | translate}}</td>
                <td>{{getAmmountValue(transaction)}}</td>
                <td>{{parseDate(transaction.timestamp)}}</td>
                <td><a [routerLink]="'/support/transactions/'+deviceId+'/transaction/'+transaction._id" class="fa fa-eye"></a></td>
            </tr>
        </ng-template>

        <ng-template #notHaveTransactions>

            <tr>
                <td colspan="5" class="empty">{{'No Records' | translate}}</td>
            </tr>
        </ng-template>
    </tbody>
</table>