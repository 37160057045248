<div class="template-editor-main-container">

  <div class="text-right template-inputs">
    <div  *ngIf="!templateId">
      <label *ngIf="validateAcl('createTemplates') && validateAcl('createPrivateTemplate')  " class="material-label" for="set-public-template">
        Template publico <input id="set-public-template" type="checkbox" [(ngModel)]="templateIsPublic">
      </label>
      <label *ngIf="!validateAcl('createTemplates') && validateAcl('createPrivateTemplate')  " class="material-label" for="set-public-template">
        <b>Template privado</b>
      </label>
      <label *ngIf="validateAcl('createTemplates') && !validateAcl('createPrivateTemplate')  " class="material-label" for="set-public-template">
        <b>Template publico</b>
      </label>
    </div>
    <div  *ngIf="!templateId">
      <label class="material-label" for="template-page">
        Página
        <select id="template-page" [(ngModel)]="pageId" (change)="restartForm()">
          <option *ngFor="let page of pagesService.getPages()" value="{{page.varname}}">{{page.alias}}</option>
        </select>
      </label>
    </div>
  </div>

  <div id="chart-engine-container">

    <div id="template-editor"></div>
    
  </div>

</div>