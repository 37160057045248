<breadcrumb [routeBreadcrumb]="breadcrumb"></breadcrumb>

<h2 *ngIf="model" >{{getTitle()}} </h2>

<!--begin extra-filter-bar -->

<div *ngIf="hasSearch" class="row">
  <div class="col-md-6 col-md-offset-3">
    <input type="text" class="form-control" placeholder="{{'Search of by fields' | translate}}: {{getSearchPlaceholder()}}" (keyup)="generateSearchQuery()" [(ngModel)]="searchQuery">

</div>
<button [class]="hasExtraFiltersApplied() ? 'btn btn-warning' : 'btn btn-primary'" *ngIf="hasExtraFilters" (click)="openExtrafiltersModal(template)">
    <i title="filtros" class="list-option-endpoint fa fa-filter" ></i>
    <span *ngIf="hasExtraFiltersApplied(); else NotFilters">{{'Advanced Filters' | translate}}{{'*'}}</span>
    <ng-template #NotFilters>
        <span>{{'Advanced Filters' | translate}}</span>
    </ng-template>
</button>

<!--end extra-filter-bar -->
<!--begin notifications list -->

<div class="row">
  <div class="col-md-12">
    <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="text-center" *ngFor="let param of params">
                {{param.alias | translate}}
                <span (click)="sortField(param.attr)" *ngIf="param.options.sortable" class="sort-btn fa"
                  [ngClass]="{'fa-sort-desc': sort.param == param.attr && sort.order == 'desc', 'fa-sort-asc': sort.param == param.attr && sort.order == 'asc', 'fa-sort': sort.param != param.attr}">
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of results">
              <td *ngFor="let param of params">
                <div class="text-center">
                  <div [ngClass]='getValues(param, item) ? "" : "empty-column"'>
                    <div *ngIf="param.type != 'read-action'; else readAction">
                      {{getValues(param, item) ? getValues(param, item) : 'sem valor'}}
                      <hover-text *ngIf="showToolTip(param, item)" [values]="getValueforToolTip(param, item)"></hover-text>
                    </div>
                    <ng-template #readAction>
                      <span [class]="getActionClass(param, item)" (click)="getActionRequest($event, item)"></span>
                    </ng-template>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-component [currentPage]="currentPage" [lastPage]="lastPage" [totalRecords]="totalRecords" [recordsPerPage]="recordsPerPage" (onPageChage)="callPage($event)"></pagination-component>
  </div>
</div>
<!-- end notifications list -->
<!-- begin modal extra-filter -->

<ng-template #template>
  <div class="modal-header">
      <h4 class="modal-title pull-left">{{'Advanced filters'| translate}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <div *ngIf="hasExtraFilters">
          <extra-filter
          (valueChanged)="extraFilterChanged($event)"
          (selectedFiltersEmmitter)="selectedExtraFiltersChanged($event)"
          *ngFor="let extraFilter of model.extraFilters"
          [selectedFilters]="getSelectedExtraFiltersItems(extraFilter)"
          [relations]="model.relations"
          [filterDefinition]="extraFilter"></extra-filter>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

  <div class="modal-footer">
    <div class="form-group">
        <button type="button" class="btn btn-primary" (click)="preGetResults();modalRef.hide();saveExtraFilters()">{{'Filter' | translate}}</button>
        <button type="button" class="btn btn-primary" *ngIf="hasExtraFiltersApplied()" (click)="cleanExtraFilters();modalRef.hide();">{{'Restore Filters' | translate}}</button>
    </div>
  </div>

</ng-template>

<!-- end modal extra-filter -->