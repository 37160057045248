
<div id="inquiry-main-container" class="inquiry-main-container"
    [style.background-image]="getImage('main_background-image') ? 'url('+getImage('main_background-image') +')' : ''">
    <div class="header-bar" id="header-bar">
        <div id="inquiry-header-logo" *ngIf="getImage('header_image')"
            [style.background-image]="getImage('header_image') ? 'url('+getImage('header_image') +')' : ''"
            class="inquiry-header-logo"></div>
        <div  id="header-message" [ngClass]="noText ? 'no-text' :'header-message'">{{getConfig('header-message')}}
        </div>
        <div class="back-button" id="back-button" *ngIf="back">
            <fa name="angle-left"></fa>
            <div class="back-button-message" id="back-button-message">{{getConfig('back-button-text')}}</div>
        </div>
    </div>

    <div class="inquiry-content" id="inquiry-content">
        <div class="ticket-search" id="ticket-search">
            <div class="numeric-keyboard-container">
                <p id="keyboard-title" class="keyboard-title">{{getConfig('keyboard-title')}}</p>
                <input class="keyboard-input" type="text" name="mobile" value='{{currentTicket}}'>
                <div *ngIf="error" class="error">{{error}}</div>
                <p class="identifier-instruction" class="identifier-instruction">{{getConfig('identifier-instruction')}}</p>
                <div class="identifiers-container" id="identifiers-container">
                    <div id="scroller-left" class="scroller scroller-left" (click)="backward()" [style.visibility]="!hasPrevious() ? 'hidden' : ''">
                    </div>
                    <div id="identifiers-inner-container" class="identifiers-inner-container">
                        <span class="identifier" (click)="identifier = identifierLabel" *ngFor="let identifierLabel of currentPage || [];let i = index;">{{identifierLabel}}</span>
                    </div>
                    <div id="scroller-right" class="scroller scroller-right" (click)="forward()" [style.visibility]="!hasNext() ? 'hidden' : ''">
                    </div>
                </div>
                <p class="keyboard-secondary-label" class="keyboard-secondary-label">{{getConfig('keyboard-secondary-label')}}</p>
                <div class="keyboard-row">
                    <div class="key" (click)="write('1')">1</div>
                    <div class="key" (click)="write('2')">2</div>
                    <div class="key" (click)="write('3')">3</div>
                </div>
                <div class="keyboard-row">
                    <div class="key" (click)="write('4')">4</div>
                    <div class="key" (click)="write('5')">5</div>
                    <div class="key" (click)="write('6')">6</div>
                </div>
                <div class="keyboard-row">
                    <div class="key" (click)="write('7')">7</div>
                    <div class="key" (click)="write('8')">8</div>
                    <div class="key" (click)="write('9')">9</div>
                </div>
                <div class="keyboard-row">
                    <div id="delete-button" class="key delete-button" (click)="clear()" value="back"> <i class="fa fa-times"></i> </div>
                    <div class="key" (click)="write('0')">0</div>
                    <div id="confirm-button" class="key confirm-button" value="go"><i class="fa fa-angle-right"></i></div>
                </div>
            </div>
        </div>
        
        <!-- ##### -->

        <!-- ##### -->
    </div>

    <div id="inquiry-translations-area" class="inquiry-translations-area">
        <div id="inquiry-flags-container" class="inquiry-flags-container">
            <img src="/assets/images/flags/en.png">
            <img src="/assets/images/flags/pt.png">
        </div>
    </div>


    <div id="inquiry-footer" class="inquiry-footer"  [style.background-image]="getImage('footer_image') ? 'url('+getImage('footer_image') +')' : ''" >
    </div>

    <!-- footer_image -->