<label [formGroup]="formGroup" for="{{identifier}}" class="col-md-12">
  <note [text]="param.note"></note>
  <p *ngIf="error" [ngClass]="{'error-label': error}"><small [innerHTML]="error"></small></p>

  <table class="table table-striped">
    <thead>
        <th>{{translateTerm('Question')}}</th>
        <th>{{translateTerm('Type of response')}}</th>
        <th>{{translateTerm('Order')}}</th>
    </thead>
    <tbody [formArrayName]="param.attr">
        <tr *ngFor="let control of formGroup.controls[param.attr]['controls']; let i = index;" [formGroup]="control">
          <td><input [formControlName]="'question'" type="text" class="form-control"></td>
          <td>
              <select class="form-control" [formControlName]="'type_id'">
                <option value="">Selecione</option>
                <option *ngFor="let type of types" value="{{type.id}}">{{type.alias}}</option>
              </select>
          </td>
          <td><input [formControlName]="'order'" type="number" class="form-control"></td>
            <td><a (click)="removeRow(i)">X</a></td>
        </tr>
        {{setValue()}}
    </tbody>
  </table>

  <a class="btn btn-primary" (click)="addRelationRow()">{{translateTerm('Add')}} {{translateTerm(param.alias)}}</a>
</label>
