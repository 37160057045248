<!-- button to down -->
<div class="position_btn_up">
  <a id="btn_down" (click)="btnToDown()" class="btnUpDown">
    <i class="fa fa-arrow-circle-down fa-3x" aria-hidden="true"></i>
  </a>
</div>
<!-- end button -->
<h2 #top>{{getTitle()}}</h2>
<div id="form-model-component">
    <form *ngIf="formModel" enctype="multipart/form-data" [formGroup]="formModel" (submit)="submitForm($event)">
        <div *ngFor="let param of params" class="col-md-12 form-group" [ngClass]="{'hidden': param.type=='hidden' || !paramIsValid(param)}">
            <input-attribute (agencyFilterEmitter)="agencyFilter($event)" *ngIf="paramIsValid(param)" [submitErrors]="submitErrors" [recordId]="recordId" [error]="getError(param.attr)" [record]="record" [formGroup]="formModel" [Model]="Model" [param]="param" [relations]="relationalModelsRelations"></input-attribute>
        </div>
        <div #bottom class="col-md-12 form-group">
            <div class="form-btn-padding pull-left">
                <button class="btn btn-success" type="submit">{{'Save' | translate}}</button>
            </div>
            <div class="form-btn-padding pull-left">
                <a (click)="clickCancel()" class="btn btn-danger">{{'Cancel' | translate}}</a>
            </div>
            <div class="form-btn-padding pull-left" *ngFor="let button of Model.customFormButtons">
                <a [classList]="button.buttonClassList" (click)="clickCustomFormButton(button)" >{{button.label | translate}}</a>
            </div>
        </div>
  </form>
</div>

<!-- Button to up -->
<div class="position_btn_down">
  <a id="btn_up" (click)="btnToUp()" class="btnUpDown">
    <i class="fa fa-arrow-circle-up fa-3x" aria-hidden="true"></i>
  </a>
</div>
<!-- end button -->

