
<h2>{{ tradService.transformTranslate(Model.modelAliasSingular)}}</h2>
<div id="form-model-component">
  <form *ngIf="formModel" enctype="multipart/form-data" [formGroup]="formModel" (submit)="submitForm($event)">
    <div *ngFor="let param of params" class="col-md-12 form-group" [ngClass]="{'hidden': param.type=='hidden'}">
      <input-attribute *ngIf="paramIsValid(param)" [error]="getError(param.attr)" [record]="record" [formGroup]="formModel" [Model]="Model" [param]="param"></input-attribute>
    </div>
    <div class="col-md-12 form-group">
      <div class="form-btn-padding pull-left">
          <button class="btn btn-success" type="submit">Guardar</button>
      </div>
      <div class="form-btn-padding pull-left">
          <a href="/{{Model.modelName}}" class="btn btn-danger">Cancelar</a>
      </div>
    </div>
  </form>
</div>
