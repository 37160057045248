<div [ngClass]="!isCollapsed ? 'side-bar full' : 'side-bar collapsed'">
    <div class="side-bar-content">
        <div class="collapse-button" (click)="changeCollapse()">
            <div class="menu-label">
                <div class="menu-icon">
                    <i [ngClass]="!isCollapsed ? 'fa fa-angle-double-left' : 'fa fa-angle-double-right'"></i>
                </div>

            </div>
        </div>
        <ng-container *ngFor="let item of menu;">
            <a *ngIf="item.canNavigate" class="side-menu-content-menu" routerLink="{{item.url}}">
                <div [ngClass]="activated(item) ? 'menu-label visited' : 'menu-label'">
                    <div class="menu-icon">
                        <span class="{{item.icon}}"></span>
                    </div>
                    <div class="menu-title">
                        {{translateTerm(item.name)}}
                    </div>
                </div>
            </a>
            <div *ngIf="!item.canNavigate" title="{{translateTerm(item.name)}}" class="side-menu-content-menu"
                id="group-menu-{{item.id}}" (mouseenter)="hoverCollapsed(item)" (tap)="hoverCollapsed(item)"
                (mouseleave)="controlMouseLeave()" (click)="isCollapsed ? toogleCollapsed(item) : toggleFull(item)">
                <div [ngClass]="urlActivated == item.url ? 'menu-label visited' : 'menu-label unvisited'">
                    <div class="menu-icon">
                        <span class="{{item.icon}}"></span>
                    </div>
                    <div class="menu-title">
                        {{translateTerm(item.name)}} <i
                            [ngClass]="item.subMenu.length > 0 ? 'fa fa-angle-down' : ''"></i>
                    </div>
                </div>
                <div *ngIf="item.subMenu.length > 0" class="side-menu-childs-container hide-menu">
                    <a class="side-menu-content-menu-child" *ngFor="let subMenu of item.subMenu"
                        routerLink="{{subMenu.url}}">
                        <div [ngClass]="urlActivated == subMenu.url ? 'menu-label visited' : 'menu-label'">
                            <div class="menu-icon">
                                <span class="{{subMenu.icon}}"></span>
                            </div>
                            <div class="menu-title">
                                {{translateTerm(subMenu.name)}}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </ng-container>

    </div>
</div>