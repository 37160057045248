<div #styleContainer></div>
<div id="kiosk-buttons-container">
    <div id="kiosk-main-container-title" class="card-id-message">
        {{ getValue('cardid-message') }}
    </div>
    <div id="kiosk-buttons-container-outer">
        <div id="kiosk-buttons-container-inner">
            <div class="kiosk-button manual-cardid-button">
                <span class="kiosk-button-label no-cardid-label">
                    {{ getValue('cardid-input_id') }}
                </span>
            </div>
            <div class="kiosk-button no-cardid-button">
                <span class="kiosk-button-label no-cardid-label">
                    {{ getValue('cardid-noid_button') }}
                </span>
            </div>
        </div>
    </div>
</div>


