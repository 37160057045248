<div #styleContainer></div>
        <div id="kiosk-buttons-container" class="service-groups-button-container">
            <div id="kiosk-main-container-title" class="service-groups-container-title">
                {{ getValue('servicegroups-message') }}</div>
            <div id="kiosk-buttons-container-outer" class="service-groups-container-outer">
                <div id="kiosk-buttons-container-inner" class="service-groups-container-inner">
                    <div class="kiosk-button service-group-button">
                        <span class="kiosk-button-label service-group-label">Grupo de serviços 1</span>
                    </div>
                    <div class="kiosk-button service-group-button">
                        <span class="kiosk-button-label service-group-label">Grupo de serviços 2</span>
                    </div>
                </div>
            </div>
        </div>
