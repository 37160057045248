<a *ngIf="removeMode()" title="{{'Remove Hardware' | translate}}" class="list-option-endpoint fa fa-trash" (click)="submit()"></a>
<a *ngIf="editMode()" title="{{'Edit Hardware' | translate}}" class="list-option-endpoint fa fa-pencil" (click)="openModal(template)"></a>
<button *ngIf="createMode()" title="{{'Add Hardware' | translate}}" (click)="openModal(template)"
    class="btn btn-primary tasks-button"><span class="fa fa-pencil"></span> {{'Add Hardware' | translate}}</button>
<ng-template #template>
    <div class="modal-header">
        <div class="col-xs-12 text-center">
            <h4 *ngIf="createMode()" class="modal-title pull-left"><b>{{'Add Hardware' | translate}}</b></h4>
            <h4 *ngIf="editMode()" class="modal-title pull-left"><b>{{'Edit Hardware' | translate}} {{hardwareModel.hardware_model.brand}} - {{hardwareModel.hardware_model.model}}</b></h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <form class="form-group">
            <div *ngIf="createMode() && mode == 'create'"  class="col-md-12">
                <div class="col-md-12 form-group">
                    <label>
                        <span [ngClass]="{'error-label': error}" class="label-checkbox-list" >
                        {{'Hardware model' | translate}}
                        </span>
                    </label>
                    <p *ngIf="error" [ngClass]="{'error-label': error}"><small>{{getError()}}</small></p>
                    <select [(ngModel)]="hardwareId" name="hardwareId" class="form-control">
                        <option [value]="null">{{'Choose an option' | translate}}</option>
                        <option *ngFor="let hardware of hardwareModels" [value]="hardware.id">{{hardware.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-12 form-group"><br>
                <label for="status-flag" class="form-element-spacing">
                <span>{{ 'Status' | translate }}</span>
                    <input id="status-flag" name="status" [(ngModel)]="statusFlag" type="checkbox">
                </label>
            </div>
            <div class="col-md-12 form-group"><br>
                <button type="button" (click)="submit()"
                    class="btn btn-success btn-default pull-right">{{'Submit' | translate}}</button>
            </div>
        </form>
    </div>
</ng-template>