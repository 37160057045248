<div #styleContainer></div>
            <div id="kiosk-buttons-container">
                <div class="ticket-output-selections-container-title">
                    {{getValue('ticketoutput-message')}}</div>
                <div id="kiosk-buttons-container-outer">
                    <div id="kiosk-buttons-container-inner">
                        <div class="kiosk-button ticket-output-selection-button">
                            <span
                                class="kiosk-button-label ticket-output-selection-label">{{ getValue('ticketoutput-printbutton_message') }}</span>
                        </div>
                        <div class="kiosk-button ticket-output-selection-button">
                            <span
                                class="kiosk-button-label ticket-output-selection-label">{{ getValue('ticketoutput-smsbutton_message') }}</span>
                        </div>
                    </div>
                </div>
            </div>