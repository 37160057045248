<breadcrumb [routeBreadcrumb]="breadcrumb"></breadcrumb>

<div class="row">
    <div class="col-md-12">
        <h1>{{title | translate}}</h1>
    </div>
    <div class="col-md-12">
        <button class="btn btn-primary" id="fast" (click)="changeViewFast()">{{'Last_' | translate}} 10</button>
        <button class="btn btn-primary" id="search" (click)="changeSearch()">{{'Search' | translate}}</button>
    </div>
    <support-search *ngIf="onSearch" [services]="services" [serviceGroups]="serviceGroups" (paramsSearch)="addParams($event)" [searchForm]="searchQueryParam"></support-search>
    <div class="col-md-12 table-responsive">
        <ticket-table [ticketArray]="results" [services]="services" [agency]="agency"></ticket-table>
    </div>
    
</div>