<label class="checkbox-list-container" for="{{identifier}}" class="col-md-12" [formGroup]="formGroup">
    <span *ngIf="!hideLabel" [ngClass]="{'error-label': error}" class="label-checkbox-list">
        {{param.alias | translate }}
        <tooltip-component [sentence]="param.tooltip"></tooltip-component>
    </span>
    <note *ngIf="!hideLabel" [text]="param.note"></note>

    <p *ngIf="error" [ngClass]="{'error-label': error}">
        <small>{{error}}</small>
    </p>
    <div>
        <angular4-multiselect
            [id]="identifier"
            [data]="getRelation()"
            [(ngModel)]="checked"
            [settings]="dropdownSettings"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="OnItemDeSelect($event)"
            [ngModelOptions]="{standalone: true}"
        >
        {{setValue()}}
        </angular4-multiselect>
    </div>
</label>