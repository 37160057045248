<div #styleContainer></div>
        <div id="kiosk-buttons-container">
            <div id="kiosk-main-container-title">
                {{ getValue('priority-message') }}
            </div>

            <div *ngIf="!servicesToDisplay.length" id="kiosk-buttons-container-outer">
                <div id="kiosk-buttons-container-inner">
                    <div class="kiosk-button">
                        <div class="kiosk-button-identifier">
                            <div class="kiosk-button-itentifier-text">A</div>
                        </div>
                        <span class="kiosk-button-label">Serviço</span>
                    </div>
                    <div class="kiosk-button service-out-of-time">
                        <div class="kiosk-button-identifier">
                            <div class="kiosk-button-itentifier-text">B</div>
                        </div>
                        <span class="kiosk-button-label">Serviço Fora de Horas</span>
                    </div>
                    <div class="kiosk-button">
                        <div class="kiosk-button-identifier">
                            <div class="kiosk-button-itentifier-text">C</div>
                        </div>
                        <span class="kiosk-button-label">Serviço</span>
                    </div>
                    <div *ngIf="hasValue('kiosk_uses_appointments')" class="kiosk-button appointment-kiosk-button">
                        <span class="kiosk-button-label appointment-kiosk-button-label">{{ getValue('appointments-message') }}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="servicesToDisplay.length" id="kiosk-buttons-container-outer">
                <div id="kiosk-buttons-container-inner">
                    <div *ngFor="let service of servicesToDisplay" class="kiosk-button">
                        <div class="kiosk-button-identifier">
                            <div class="kiosk-button-itentifier-text">{{service.identifier_priority ? service.identifier_priority : service.identifier }}</div>
                        </div>
                        <span class="kiosk-button-label">{{service.alias}}</span>
                    </div>
                    <div *ngIf="hasValue('kiosk_uses_appointments')" class="kiosk-button appointment-kiosk-button">
                        <span class="kiosk-button-label appointment-kiosk-button-label">{{ getValue('appointments-message') }}</span>
                    </div>
                </div>
            </div>
        </div>
   