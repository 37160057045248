<h2>{{Model.modelAliasSingular}}</h2>

<div id="kiosk-editor-component">
  <form *ngIf="formModel" enctype="multipart/form-data" [formGroup]="formModel" (submit)="submitForm($event)">
    <div class="col-md-12">
    <div class="kiosk-input">
        <div *ngFor="let param of params" class="col-md-12 form-group" [ngClass]="{'hidden': param.type=='hidden'}">
            <input-attribute (agencyFilterEmitter)="agencyFilter($event)" *ngIf="paramIsValid(param)" [error]="getError(param.attr)"
            [record]="record" [formGroup]="formModel" [Model]="Model" [param]="param" [relations]="relationalModelsRelations"></input-attribute>
        </div>
    </div>
    </div>
    <div class="clearfix"></div>
    <div class="form-element-spacing">
    <tabset>

    <tab heading="Editor de CSS">
            <div class="form-group">
            <div class="col-md-4">
                <div class="form-group">
                <label>
                    <span>{{'CSS File' | translate }}</span>
                    <textarea class="form-control" name="cssFile" id="" rows="50" cols="80" [formControl]="formRules['cssFile']"></textarea>
                </label>
                </div>
                <a class="btn btn-info" (click)="confirmReset()">Reset Css</a>
            </div>
            <div class="col-md-8">
                <div class="slidecontainer kiosk-input" style="margin-bottom: 15px;">
                    <label for="">Escala : {{scaleT}}</label>
                    <input type="range" min="0" max="100" value="60" class="slider" id="myRange" (change)="changeScale($event)">
                </div>
                <div class="form-element-spacing" style="padding-left: 0 !important; padding-right: 0 !important;">
                    <label for="">Preview</label>
                    <tabset>
                        <tab *ngIf="hasValue('ticket_output_per_sms')" heading="Ecrã de Sms">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [screen]="'smsScreen'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab *ngIf="hasValue('ticket_output_per_sms')" heading="Ecrã Final (sms)">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [hideFooter]="true" [hideHeader]="true" [screen]="'thanksScreenSms'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab *ngIf="hasValue('ticket_output_per_sms')" heading="Ecrã de seleção sms/papel">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [screen]="'ticketOutputSelection'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab heading="Página de Seleção de Serviço" id="tab1">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [services]="Model.relations.services_to_display" [screen]="'servicesScreen'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab heading="Página sem serviços disponiveis" id="tab1">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [screen]="'noServices'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab heading="Página de Serviço Prioritário">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [services]="Model.relations.services_to_display" [screen]="'priorityPage'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab heading="Página Final">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [hideFooter]="true" [hideHeader]="true" [screen]="'thanksScreen'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab *ngIf="hasValue('kiosk_uses_appointments')" heading="Pagina de confirmação (numérica)">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [screen]="'confirmationPage'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab *ngIf="hasValue('kiosk_uses_appointments')" heading="Pagina de confirmação (alfanumérica)">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [screen]="'confirmationAlphanumeric'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab *ngIf="!hasValue('ticket_output_per_sms') && hasValue('printer_disabled')" heading="Senha no Ecrã (com impressora e sms desativados)">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [screen]="'ticketScreen'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab heading="Pagina de erro">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [hideFooter]="true" [hideHeader]="true" [screen]="'errorPage'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab *ngIf="hasValue('has_client_identification')" heading="Pagina de identificação">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [hideFooter]="false" [hideHeader]="false" [screen]="'identificationPage'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab *ngIf="hasValue('has_client_identification')" heading="Pagina final de identificação">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [hideFooter]="true" [hideHeader]="true" [screen]="'readcardFinish'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab *ngIf="hasValue('has_client_identification')" heading="Pagina identificação manual">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [hideFooter]="false" [hideHeader]="false" [screen]="'identificationInput'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab heading="Pagina de loading">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [hideFooter]="true" [hideHeader]="true" [screen]="'loadingPage'" [images]="images"></simple-kiosk>
                        </tab>
                        <tab *ngIf="hasValue('start_on_welcome_page')" heading="Página de início">
                            <simple-kiosk [languages]="Model.relations.languages" [scaleT]="scaleT" [formGroup]='formModel' [hideFooter]="false" [hideHeader]="false" [screen]="'startPage'" [images]="images"></simple-kiosk>
                        </tab>
                    </tabset>
                </div>
            </div>
            </div>
        </tab>

        <tab heading="Parâmetros">
            <div class="col-md-8 form-group">

                <br>
                <accordion [showArrows]="true">
                    <accordion-group *ngFor="let configTitle of configsPerTitleKeys;" [isOpened]="false"
                        heading="{{getAliasForConfig(configTitle)}}">
                        <div id="collapseOne" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <div [ngClass]="getConfigs(configTitle,'fileConfigurations').length > 0 ? 'col-md-6' : 'col-md-12'"
                                    *ngIf=" getConfigs(configTitle,'stringConfigurations').length > 0">
                                    <div class="col-md-12">
                                        <label for="">{{'Text' | translate}}</label>
                                    </div>
                                    <div class="form-group"
                                        *ngFor="let config of getConfigs(configTitle,'stringConfigurations'); let i = index;">
                                        <label [formGroup]="formModel" for="{{ config.id }}" class="col-md-12">
                                            <span
                                                [ngClass]="{'error-label': submitErrors && submitErrors[config.id]}">{{ config.label  | translate}}</span>
                                            <tooltip-component [sentence]="config.description"></tooltip-component>
                                            <p *ngIf="submitErrors && submitErrors[config.id]"
                                                [ngClass]="{'error-label': submitErrors && submitErrors[config.id]}">
                                                <small>{{submitErrors[config.id] | translate}}</small>
                                            </p>
                                        </label>
                                        <div class="col-xs-12">
                                            <input id="{{ config.id }}" (change)="changeEvent($event,config, i)"
                                                (click)="clickEvent(config, i)" [name]="config.id" [formControlName]="config.id"
                                                [type]="config.type"
                                                [ngClass]="{'form-control': config.type == 'text', 'hidden': images[config.id]}"
                                                >
                                            <a class="btn btn-md btn-danger" *ngIf="config.default_value" (click)="resetToDefault(config.id, config.default_value)" style="margin-top: 5px;"> <i class="fa fa-refresh"></i></a>
                                            <button class="btn btn-danger" *ngIf="images[config.id]"
                                                (click)="removeImage(config)">{{'Delete' | translate}} <i
                                                    class="glyphicon glyphicon-trash"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div [ngClass]="getConfigs(configTitle,'stringConfigurations').length > 0 ? 'col-md-6' : 'col-md-12'"
                                    *ngIf=" getConfigs(configTitle,'fileConfigurations').length > 0">
                                    <div class="col-md-12">
                                        <label for="">Ficheiros</label>
                                    </div>
                                    <div class="form-group"
                                        *ngFor="let config of  getConfigs(configTitle,'fileConfigurations'); let i = index;">
                                        <label [formGroup]="formModel" for="{{ config.id }}" class="col-md-12">
                                            <span
                                                [ngClass]="{'error-label': submitErrors && submitErrors[config.id]}">{{ config.label | translate }}</span>
                                            <tooltip-component [sentence]="config.description"></tooltip-component>
                                            <p *ngIf="submitErrors && submitErrors[config.id]"
                                                [ngClass]="{'error-label': submitErrors && submitErrors[config.id]}">
                                                <small>{{submitErrors[config.id] | translate }}</small>
                                            </p>
                                        </label>
                                        <div class="col-xs-12">
                                            <input id="{{ config.id }}" (change)="changeEvent($event,config, i)"
                                                (click)="clickEvent(config, i)" [name]="config.id" [formControlName]="config.id"
                                                [type]="config.type"
                                                [ngClass]="{'form-control': config.type == 'text', 'hidden': images[config.id]}">
                                            <button class="btn btn-danger" *ngIf="images[config.id]"
                                                (click)="removeImage(config)">{{'Delete' | translate}} <i
                                                    class="glyphicon glyphicon-trash"></i></button>
                                                    <a class="btn btn-success" *ngIf="images[config.id]" type="button"
                                                    download="{{getFileName(config.id, images[config.id])}}" href="{{images[config.id]}}">{{'Download' | translate}} <i
                                                        class="glyphicon glyphicon-download"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </accordion-group>
                </accordion>
            </div>
            </tab>

</tabset>
</div>
    <div class="col-md-12 form-group">
      <div class="form-btn-padding pull-left">
        <button class="btn btn-success" type="submit">{{'Save' | translate }}</button>
      </div>
      <div class="form-btn-padding pull-left">
        <a routerLink="/{{Model.modelName}}" class="btn btn-danger">{{'Cancel' | translate }}</a>
      </div>
    </div>
  </form>
</div>
