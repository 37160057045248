<breadcrumb [routeBreadcrumb]="breadcrumb"></breadcrumb>

<div class="col-md-12">
    <div class="col-md-12">
        <button (click)="goBack()" class="btn btn-primary pull-right">{{'Back' | translate}}</button>
        <button (click)="printTransaction()" class="btn btn-primary pull-right" style="margin-right:10px;">{{'Print' | translate}}</button>
    </div>
    <div class="col-md-12" id="print-title">
        <h1> {{getDeviceName()}} - {{getTransactionTitle()}}</h1>
    </div>
    <div class="col-md-4 text-left" id="print-content" [innerHtml]="getValue()"></div>
</div>