<div class="login">
    <div class="logo">
        <img class="logo-img" src="/assets/images/way2queuemanagement_logo.svg" alt="">
    </div>
    <div class="panel panel-default login-panel">
        <div class="panel-header">
            <div class="panel-heading login-title">
                <div class="col-xs-12">
                    <span class="glyphicon glyphicon-lock"></span> {{ 'Two Factor Authentication' | translate}}
                </div>
            </div>
        </div>
        <div class="panel-body">
            <div class="alert alert-success" role="alert" *ngIf="submitted && confirmed">
                <ul>
                    <li>
                        {{'Two Factor Authentication successfull' | translate }}
                    </li>
                </ul>
            </div>
                <div class="alert alert-danger" *ngIf="hasErrors()" role="alert">
                    <ul>
                        <li *ngFor="let error of getErrors()" [innerHTML]="error"></li>
                    </ul>
                </div>
                <div *ngIf="!confirmed" class="form-group"><br>

                    <label for="">{{'Authentication code' | translate}}</label>
                    <input type="password" id="loginPassword" class="form-control" [(ngModel)]="authCode"
                        >
                </div>
                <div *ngIf="!confirmed" style="margin-top: 50x;" class="form-group">
                    <a (click)="passwordResetRequest($event)"
                        class="btn btn-submit btn-default pull-right">{{'Submit' | translate}}</a>
                    <a class="btn btn-submit btn-default" (click)="login()">{{'Back' | translate}}</a>
                </div>
        </div>
    </div>
    <div class="col-xs-12"></div>
</div>