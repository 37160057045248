<div class="login">
  <div class="logo">
      <img class="logo-img" src="/assets/images/way2queuemanagement_logo.svg" alt="">
  </div>
    <div class="panel panel-default login-panel">
      <div class="panel-header">
        <div class="panel-heading login-title">
          <div class="col-xs-12"><span class="glyphicon glyphicon-lock"></span> {{'Authentication' | translate}}</div>
        </div>
      </div>
      <div class="panel-body">
        <form class="login-form" [formGroup]="loginForm" (submit)="signin()">
            <div class="col-xs-12">
              <label for="">E-mail / {{'User code' | translate}}</label>
              <input type="text" id="loginEmail" class="form-control" formControlName="email">
            </div>
            <div class="col-xs-12"><br>
              <label for="">Password</label>
              <input type="password" id="loginPassword" class="form-control" formControlName="password">
            </div>
            <div class="col-xs-12"><br>
              <div class="error-container text-danger">{{errors}}</div>
              <input type="submit" class="btn btn-submit btn-default pull-right" value="Entrar">
              <button *ngIf="('useEmail' | hasEnv )" (click)="resetPassword($event)" class="btn btn-submit btn-default">{{'Recover password' | translate}}</button>
            </div>
        </form>
      </div>
    </div>
    <div class="col-xs-12"></div>
</div>