
<div  class="input-content">
    <div class="img-selection img-selection-4" [ngClass]="{'img-selection-4-active': value == '4'}" (click)="setValue('4')">
      <img  class="img-button" src="/assets/images/Carinha_4.png">
    </div>
    <div class="img-selection img-selection-3" [ngClass]="{'img-selection-3-active': value == '3'}" (click)="setValue('3')">
      <img  class="img-button" src="/assets/images/Carinha_3.png">
    </div>
    <div class="img-selection img-selection-2" [ngClass]="{'img-selection-2-active': value == '2'}" (click)="setValue('2')">
      <img  class="img-button" src="/assets/images/Carinha_2.png">
    </div>
    <div class="img-selection img-selection-1" [ngClass]="{'img-selection-1-active': value == '1'}" (click)="setValue('1')">
        <img  class="img-button" src="/assets/images/Carinha_1.png">
      </div>
  </div>
  