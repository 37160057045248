<a *ngIf="('actionDevices' | hasPermission ) && viewDefault()" title="Acções" class="list-option-endpoint fa fa-cogs" (click)="openActionsModal(template)"></a>
<button *ngIf="('actionDevices' | hasPermission ) && !viewDefault()" title="{{translateTerm('Actions')}}" (click)="openActionsModal(template)"  class="btn btn-primary tasks-button" ><span class="fa fa-cogs" ></span> {{translateTerm('Actions')}}</button>

<ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{translateTerm('Actions')}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
          <label for="endpoints">{{translateTerm('Task')}}</label>
          <select name="endpoints" id="endpoints" (change)="setAction($event)" class="form-control">
              <option value="">{{translateTerm('Choose an option')}}</option>
              <option *ngFor="let item of tasks;let i = index" value="{{i}}">
              {{item.alias}}
              </option>
          </select>
        </div>
        <div class="form-group" *ngIf="hasInput('systemctlService')" >
          <label for="systemctlService">{{translateTerm('Service')}}</label>
          <select id="systemctlService" id="systemctlService" class="form-control" [(ngModel)]="taskRequest.params['get_systemctl_service']['systemctlService']">
            <option value="">{{translateTerm('Choose an option')}}</option>
            <option *ngFor="let service of systemctlServices;let i = index" value="{{service.name}}">
            {{service.alias}}
            </option>
          </select>
        </div>
        <div class="form-group" *ngIf="hasInput('setTimezone')" >
          <label for="timezone">{{translateTerm('Timezone')}}</label>
          <select id="timezone" id="timezone" class="form-control" [(ngModel)]="taskRequest.params['timezone']['timezone']">
            <option value="">{{translateTerm('Choose an option')}}</option>
            <option *ngFor="let timezone of timezones;let i = index" value="{{timezone}}">
            {{timezone}}
            </option>
          </select>
        </div>
        <div class="form-group" *ngIf="hasInput('rotation')" >
            <div class="screen-rotation-block">
                <div class="screen-rotation-single">
                    <label for="screenRotation">{{translateTerm('Screen Rotation')}}</label>
                    <select id="screenRotation"  class="form-control" (change)="updateRotationParams()" [(ngModel)]="screenRotation">
                        <option value="">{{translateTerm('Choose an option')}}</option>
                        <option *ngFor="let direction of screenDirections;let i = index" value="{{direction.name}}">
                            {{direction.alias | titlecase }}
                        </option>
                    </select>
                </div>
                <div class="screen-rotation-single">
                    <label for="touchRotation">{{translateTerm('Touch Setting')}}</label>
                    <select  id="touchRotation" class="form-control" (change)="updateRotationParams()" [(ngModel)]="touchRotation">
                        <option value="">{{translateTerm('Choose an option')}}</option>
                        <option *ngFor="let direction of screenDirections;let i = index" value="{{direction.name}}">
                            {{direction.alias | titlecase }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group">
          <div class="form-group">
              <button type="button" class="btn btn-primary" (click)="requestAction()">Ok</button>
          </div>
        </div>
    </div>
  </ng-template>
