<div #styleContainer></div>
        <div id="kiosk-buttons-container">
            <div id="kiosk-main-container-title">
                {{ getValue('main-message') }}
            </div>
            <div id="kiosk-buttons-container-outer">
                <div class="no-services-available">
                    {{getValue('noservices-message')}}
                </div>
            </div>
        </div>