<div *ngIf="record" class="col-md-12 form-group">
    <span [ngClass]="{'error-label': error}" class="label-2fa">
        {{'Two Factor Authentication' | translate }}
    </span>
    <p><b>{{'Status' | translate}}:</b>
        <span *ngIf="totp_active == true" class="text-danger label label-success">{{'Active' | translate}}</span>
        <span *ngIf="!totp_active || totp_active == null" class="text-muted label label-danger">{{'Deactivated' |
            translate}}</span>
    </p>
    <div *ngIf="totp_active == true">

        <p class="text-danger" style="text-align:center;" *ngIf="errorMessage">{{errorMessage | translate}}</p>
        <input *ngIf="totp_active == true" name="authcode" type="number" #iauthcode="ngModel" class="form-control"
            maxlength="6" placeholder="{{'Authentication code' | translate}}" id="authcode" autocomplete="off"
            [(ngModel)]="authcode" required>


    </div>

    <div *ngIf="totp_active == false && tfa">


        <p>{{'Use your authenticator app to scan the QR code below.' | translate}}</p>


        <qrcode [qrdata]="tfa.tfaURL" [size]="256" [level]="'M'"></qrcode>

        <p>{{'You can use the code below to setup two factor authentication.' | translate}}</p>
        <p *ngIf="tfa.tempSecret">{{tfa.tempSecret}}</p>
        <p class="text-danger" style="text-align:center;" *ngIf="errorMessage">{{errorMessage | translate}}</p>
        <input name="authcode" type="number" #iauthcode="ngModel" class="form-control" maxlength="6"
            placeholder="{{'Authentication code' | translate}}" id="authcode" autocomplete="off" [(ngModel)]="authcode"
            required>
    </div>
    <div class="row">
    <div class="col-md-12">

        <a class="btn btn-md btn-danger" [routerLink]="[ '/profile/'+record.id ]">{{'Back' | translate}}</a>
        <a *ngIf="totp_active == false && tfa" (click)="confirm()" class="btn btn-md btn-primary btn-block">{{'Enable 2FA' | translate}}</a>
        <a *ngIf="totp_active == true" class="btn btn-md btn-primary btn-block" (click)="disabledTfa()">{{'Disable 2FA' | translate}}</a>
    </div>
    </div>
</div>