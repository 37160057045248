
<img *ngIf="param.type == 'file'; else colorParam" [src]="getValue()">
<ng-template #colorParam>
  <div  *ngIf="param.type == 'color'; else booleanAction" class="color-attr" [style.backgroundColor]="getValue()"></div>
</ng-template>
<ng-template #booleanAction>
  <div  *ngIf="param.type == 'boolean-action'; else statusParam">
    <span *ngIf="getValue()" (click)='runRequest($event)' class="check-symbol fa fa-check-circle"></span>
    <span *ngIf="!getValue()" (click)='runRequest($event)' class="uncheck-symbol fa fa-times-circle"></span>
  </div>
</ng-template>
<ng-template #statusParam>
  <div  *ngIf="param.type == 'status'; else linkParam">
    <span *ngIf="param.attr != 'connection_status'" title="{{getTooltip()}}" class="{{getStatusClass()}}">{{getValue() | translate}}</span>
    <span *ngIf="param.attr == 'connection_status'" title="{{'Last Seen' | translate}}: {{getTooltip() | convertToTimezone}}" class="{{getStatusClass()}}">{{getValue() | translate}}</span>
  </div>
</ng-template>
<ng-template #linkParam>
  <div *ngIf="param.link ; else readAction">
    <list-url [param]="param" [record]="record" [model]="model"></list-url>
</div>
</ng-template>
<ng-template #readAction>
  <div  *ngIf="param.type == 'read-action'; else defaultParam">
    <span *ngIf="getValue()" (click)='setReadRequest($event)' class="check-symbol fa fa-check-circle"></span>
    <span *ngIf="!getValue()" (click)='setReadRequest($event)' class="uncheck-symbol fa fa-times-circle"></span>
  </div>
</ng-template>
<ng-template #defaultParam>
  <div  [ngClass]='getValue() ? "" : "empty-column"'>
    {{getValue() ? getValue() : 'sem valor'}}
    <hover-text *ngIf="showToolTip()" [values]="getValueforToolTip()"></hover-text>
  </div>
</ng-template>




