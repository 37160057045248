<div id="filter-bar-container">

  <div id="filter-bar-mobile-container">
    <div class="btn btn-default mobile-buton" (click)="toogleFilter()">{{translateTerm('General Filters')}} <i class="fa fa-filter" ></i></div>
    <div *ngIf="beginningDate && endDate">De <input type="text" value="{{ beginningDate }}" disabled > a <input type="text" value="{{ endDate }}" disabled ></div>
  </div>

  <div class="filter-background" [ngClass]="{'mobile-filter': mobileMenuClass}"></div>

  <div id="filter-container" (click)="checkToogleFilter($event)" [ngClass]="{'mobile-filter': mobileMenuClass}">
    <div id="filter-bar" ></div>
  </div>