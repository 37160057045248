<breadcrumb [routeBreadcrumb]="breadcrumb"></breadcrumb>
<h2>{{title}}</h2>

<div class="col-md-4">

    <div class="form-group">
        <label for="name">{{translateTerm("Name")}}</label>
        <p *ngIf="errorDict.name" [ngClass]="{'error-label': errorDict.name}"><small [innerHTML]="errorDict.name"></small></p>
        <input type="text" class="form-control" id="name" required [(ngModel)]="model.name" name="name">
    </div>
    <div class="form-group">
        <label for="name">{{translateTerm("Template type")}}</label>
        <p *ngIf="errorDict.sms_template_type_id" [ngClass]="{'error-label': errorDict.sms_template_type_id}"><small [innerHTML]="errorDict.sms_template_type_id"></small></p>
        <select class="form-control" id="power" required [(ngModel)]="model.sms_template_type_id"
            name="sms_template_type_id">
            <option [value]="null">{{translateTerm('Choose an option')}}</option>
            <option *ngFor="let templateType of Model.relations.sms_template_type_id" [value]="templateType.id">
                {{translateTerm(templateType.name)}}
            </option>
        </select>
    </div>
    <div *ngIf="templateTypeNeedsValue()" class="form-group">
        <label for="name">{{translateTerm("Value")}}</label>
        <input type="text" class="form-control" required [(ngModel)]="model.value" name="value">
    </div>

    <div class="form-group">
        <label>{{'Agencies' | translate }} <tooltip-component [sentence]=""></tooltip-component> </label>
            <angular4-multiselect
                [data]="Model.relations.agencies"
                [(ngModel)]="model.agencies"
                [settings]="dropdownSettings"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="OnItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)"
                [ngModelOptions]="{standalone: true}"
            >
            </angular4-multiselect>
    </div>




</div>
<div class="col-md-8">

    <div class="col-md-12 form-group">
        <label for="name">Template</label>
    </div>
    <tabset>
        <tab *ngFor="let content of model.content" heading="{{content.lang.alias}}"
            active="content.lang.language_code == 'pt'">
            <sms-template [content]="content" [errorDict]="errorDict" (contentChange)="updateContent($event)"></sms-template>
        </tab>
    </tabset>
</div>
<div class="col-md-12">
    <div class="form-group">
        <button class="btn btn-success" (click)="saveTemplate()">{{'Save' | translate}}</button>
        <button class="btn btn-danger" (click)="goHome()">{{'Cancel' | translate}}</button>
    </div>
</div>