<breadcrumb  [routeBreadcrumb]="breadcrumbArray"></breadcrumb>

<h2>{{translateTerm("Action Logs")}} </h2>


<div *ngIf="actionLog" class="col-md-12">
    <ul>
        <li><b> {{translateTerm("User")}}: </b>{{getLogValue('user_name')}}</li>
        <li><b> {{translateTerm("Client")}}: </b>{{getLogValue('client_name')}}</li>
        <li><b> {{translateTerm("Date")}}: </b>{{getLogValue('created_at')}}</li>
        <li><b> {{translateTerm("Operation")}}: </b>{{getLogValue('action')}}</li>
        <li><b> {{translateTerm("Model")}}: </b>{{getLogValue('model')}}</li>
    </ul>
    <label>
        {{translateTerm('Original')}}
    </label>
    <pre>{{ getEncodedField('original') | json}}</pre>
    <label>
        {{translateTerm('Changes')}}
    </label>
    <pre>{{ getEncodedField('changes') | json}}</pre>
    <label>
        {{translateTerm('Full Model')}}
    </label>
    <pre>{{ getEncodedField('full_model') | json}}</pre>
</div>

<div class="col-md-12 form-group">
    <div class="form-btn-padding pull-left">
        <a (click)="goBack()" class="btn btn-danger">{{translateTerm('Cancel')}}</a>
    </div>
</div>