<div class="notifications" *ngIf="hasNotificationAcl()" (click)="toggleDropdown()" (clickOutside)="outsideClickDropdown()">
  <div class="notifications-content">
    <span class="badge-notify" *ngIf="notificationsCount > 0">
      {{maxViewNotificationsCount(notificationsCount)}}
    </span>
    <span class="bell-btn fa fa-bell" [class.bell]="notificationsCount > 0 && !notificationsPage"></span>
  </div>
</div>
<div class="notifications_dropdown" [class.show]="dropdownVisible">
  <div class="pointer"></div>
  <div class="dropdown">
    <div class="dropdown_title">
      <h3>{{'Notifications' | translate}}</h3>
    </div>
    <div *ngIf="notifications; else loadingNotifications" class="dropdown_content_notifications">
      <div *ngFor="let notification of notifications;">

        <div [class]="notification.read == 0 ? 'notify_active notify_item': 'notify_item'" >
          <div class="notify_info">
            <p>{{notification.title}}</p>
          </div>
          <div class="notify_time">
            <span class="text-muted">{{calculateUpdateTime(notification.updated_at)}}</span>
          </div>
        </div>

      </div>
    </div>
    <ng-template #loadingNotifications>
      <div class="notify_loading">
        <div class="loader"></div>
      </div>
    </ng-template>
    <div class="dropdown_seeAll">
      <a (click)="openNotificationsPage()"> {{'Show All Notifications' | translate }}</a>
      <a (click)="openNotificationsSettings()" _ngcontent-c5="" class="fa fa-cog"></a>
    </div>
  </div>
</div>