<div class="text-left">

    * DADOS DE CLIENTE *<br>
    Cliente: 35632<br>
    Nome: John Doe<br>
    <br>
    * DADOS DE DOCUMENTO *<br>
    Tipo de documento: Factura<br>
    Referência: 292624<br>
    Valor: 9.08€<br>
    <br>
    * COMPROVATIVO DE OPERAÇÃO *<br>
    ID TPA: 000048839<br>
    13-10-2021 16:11:28<br>
    Pr:27 Tr:6 Ms: 39<br>
    Cartão: *******0077<br>
    MAESTRO<br>
    Compra: 50.00€<br>
    Comissão: +0.00€<br>
    ID Estab: 0000802140<br>
    Aut: 005435 MA EEA;
</div>