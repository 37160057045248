<ul class="pagination">
  <li *ngIf="canGoBack()"><a (click)="setPage(1)" class="fa fa-backward"></a></li>
  <li *ngIf="canGoBack()"><a (click)="prevPage()" class="fa fa-chevron-left"></a></li>
  <li *ngFor="let pageNumber of pageRange" [ngClass]="{'active': pageNumber==currentPage}"><a (click)="setPage(pageNumber)">{{pageNumber}}</a></li>
  <li *ngIf="canGoForward()"><a (click)="nextPage()" class="fa fa-chevron-right"></a></li>
  <li *ngIf="canGoForward()"><a (click)="setPage(lastPage)" class="fa fa-forward"></a></li>
</ul>

<h5 class="pull-right totalRecords" *ngIf="totalRecords">{{'Total Records' | translate}}: {{totalRecords}}</h5>




