<div class="time-picker-container col-md-12">
    <div class="time-picker-separator">
        <label for="time">
            <span *ngIf="!hideLabel" [ngClass]="{'error-label': error}">{{ translateTerm(param.alias) }}</span>
            <tooltip-component [sentence]="param.tooltip"></tooltip-component>
            <note [text]="param.note"></note>
            <p *ngIf="error" [ngClass]="{'error-label': error}"><small [innerHTML]="error"></small></p>

            <timepicker [(ngModel)]="time" [showMeridian]="false" (ngModelChange)="onTimeChange($event)"></timepicker>
        </label>
    </div>
</div>
