
<div id="inquiry-main-container" class="inquiry-main-container" [style.background-image]="getImage('main_background-image') ? 'url('+getImage('main_background-image') +')' : ''" >
    <div *ngIf="!noHeader" class="header-bar" id="header-bar">
        <div id="inquiry-header-logo" *ngIf="getImage('header_image')" [style.background-image]="getImage('header_image') ? 'url('+getImage('header_image') +')' : ''" class="inquiry-header-logo"></div>
        <div id="header-message" class="header-message">{{getConfig('header-message')}}</div>
        <div class="back-button" id="back-button" *ngIf="back">
            <fa name="angle-left"></fa>
            <div class="back-button-message" id="back-button-message">{{getConfig('back-button-text')}}</div>
        </div>
    </div>

    <div class="inquiry-content" id="inquiry-content">
        <div *ngIf="inquiry" class="thanks-page-content" id="thanks-page-content"   [style.background-image]="getImage('last_background-image') ? 'url('+getImage('last_background-image') +')' : ''">
            <div class="thanks-page-message" id="thanks-page-message">
                {{inquiry.thanks_message}}
            </div>
        </div>
    </div>

            <div *ngIf="!noHeader" id="inquiry-translations-area" class="inquiry-translations-area">
                <div id="inquiry-flags-container" class="inquiry-flags-container">
            <img src="/assets/images/flags/en.png">
            <img src="/assets/images/flags/pt.png">
        </div>
    </div>

    <div *ngIf="!noHeader" id="inquiry-footer" class="inquiry-footer"  [style.background-image]="getImage('footer_image') ? 'url('+getImage('footer_image') +')' : ''" >
    </div>
    <!-- footer_image -->