<div #top></div>
<breadcrumb [routeBreadcrumb]="breadcrumbArray"></breadcrumb>
<!-- button to down -->
<div class="position_btn_up">
    <a id="btn_down" (click)="btnToDown()" class="btnUpDown">
      <i class="fa fa-arrow-circle-down fa-3x" aria-hidden="true"></i>
    </a>
  </div>
  <!-- end button -->

<h2>{{'Multi Device Tasks' | translate}}</h2>
<div  class="col-md-12">
    <div class="filter-bar-outer-container">
        <filter-bar [ngClass]="{'hidden': !displayFilterBar}" *ngIf="paramsReady" [filterBarParams]="filterBarParams"
            (updateEmitter)="filterBarListner($event)"></filter-bar>
    </div>
    <div class="col-md-12">

        <button [class]="hasExtraFiltersApplied() ? 'btn btn-warning' : 'btn btn-primary'" (click)="openExtrafiltersModal(template)">
            <i title="filtros" class="list-option-endpoint fa fa-filter"></i> {{'Advanced Filters' | translate}}

        </button>
    </div>
    <div class="col-md-12">

        <form id="multidevice-task-form" (submit)="submitForm($event)">


            <div class="checkbox-list-container">
                <span class="label-checkbox-list form-element-spacing">{{'Dispositivos' | translate}}</span>
                <label class="form-element-spacing" for="select-all-devices">
                    <input type="checkbox" id="select-all-devices" (click)="selectAll(this)">
                    {{'Select All' | translate}}
                </label>

                <label [ngClass]="getColorForUpdate(device)" class="form-element-spacing"
                    *ngFor="let device of devices; let i = index;" for='device-checkbox-{{i}}'>
                    <input (click)="validateCheckboxes()" type="checkbox" class="devices-checkbox" value="{{device.id}}"
                        id='device-checkbox-{{i}}' name="devices_ids[]">
                    {{device.name}}
                </label>
            </div>
            <div class="form-group">
                <label><span>{{'Tarefas' | translate}}</span></label>
                <select [(ngModel)]="task_id" name="task_id" class="form-control" (change)="changeTask($event)">
                    <option [value]="null">{{'Choose an option' | translate}}</option>
                    <option *ngFor="let task of tasks" [value]="task.id">{{task.alias}}</option>
                </select>
            </div>
            <div class="clearfix"></div>
            <!-- systemctl tasks -->
            <div *ngIf="isSystemCtlServiceTask" class="form-element-spacing form-group">
                <label><span>{{'Service' | translate}}</span></label>
                <select [(ngModel)]="systemctl_service" name="systemctl_service" class="form-control">
                    <option [value]="null">{{'Choose an option' | translate}}</option>
                    <option *ngFor="let service of systemctlServices" [value]="service.name">{{service.alias}}</option>
                </select>
            </div>
            <!-- systemctl tasks -->

            <!-- timezone -->
            <div class="form-element-spacing form-group" *ngIf="isSetTimezoneTask" >
                <label for="timezone"> 
                    {{'Timezone' | translate}}
                </label>
                <select id="timezone" name="timezone" class="form-control" [(ngModel)]="timezone">
                    <option [value]="''">{{'Choose an option' | translate}}</option>
                    <option *ngFor="let _timezone of timezones" [value]="_timezone">{{_timezone}}</option>
                </select>
              </div>
            <!-- timezone -->
            
            <!-- screen rotation -->

            <div *ngIf="isScreenRotation" class="form-element-spacing form-group">
                <label>{{'Screen Rotation' | translate}}</label>
                <select [(ngModel)]="screenRotation" name="screen_rotation" class="form-control">
                    <option [value]="''">{{'Choose an option' | translate}}</option>
                    <option *ngFor="let direction of screenDirections" [value]="direction.name">{{direction.alias |
                        titlecase }}</option>
                </select>
            </div>
            <div *ngIf="isScreenRotation" class="form-element-spacing form-group">
                <label><span>{{'Screen Rotation' | translate}}</span></label>
                <select [(ngModel)]="touchRotation" name="touch_rotation" class="form-control">
                    <option [value]="''">{{'Choose an option' | translate}}</option>
                    <option *ngFor="let direction of screenDirections" [value]="direction.name">{{direction.alias |
                        titlecase }}</option>
                </select>
            </div>

            <!-- screen rotation -->
            <!-- version -->
            <div *ngIf="isForceUpdate && validateAcl('editSelectedVersion')" class="form-element-spacing form-group">
                <label><span>{{'Package' | translate}}</span></label>
                <select (change)="changeClientPackage($event)" [(ngModel)]="package_id" name="systemctl_service"
                    class="form-control">
                    <option [value]="null">{{'Choose an option' | translate}}</option>
                    <option *ngFor="let package of clientPackages;let i = index" value="{{package.id}}">{{package.name}}
                    </option>
                </select>
            </div>

            <div *ngIf="isForceUpdate  && validateAcl('editSelectedVersion')" class="form-element-spacing form-group">
                <label><span>{{'Version' | translate}}</span></label>
                <select [(ngModel)]="version" name="systemctl_service" class="form-control">
                    <option [value]="null">{{'Choose an option' | translate}}</option>
                    <option *ngFor="let version of aptVersions;let i = index" value="{{version.Version}}">
                        {{version.Version}}</option>
                </select>
            </div>
            <!-- version -->
            <div #bottom class="form-group">
                    <button class="btn btn-success" type="submit">{{'Submit' | translate}}</button>

                    <button (click)="goBack($event)" class="btn btn-danger">{{'Cancel' | translate}}</button>
            </div>
        </form>
    </div>
</div>

<!-- Button to up -->
<div class="position_btn_down">
    <a id="btn_up" (click)="btnToUp()" class="btnUpDown">
      <i class="fa fa-arrow-circle-up fa-3x" aria-hidden="true"></i>
    </a>
  </div>
  <!-- end button -->

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'Advanced filters'| translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div *ngIf="hasExtraFilters">
                <extra-filter (valueChanged)="extraFilterChanged($event)"
                    (selectedFiltersEmmitter)="selectedExtraFiltersChanged($event)"
                    *ngFor="let extraFilter of deviceModel.extraFilters"
                    [selectedFilters]="getSelectedExtraFiltersItems(extraFilter)"
                    [relations]="deviceModel.relations"
                    [filterDefinition]="extraFilter"></extra-filter>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="modal-footer">
        <div class="form-group">
            <button type="button" class="btn btn-primary"
                (click)="filterButtonMethod()">{{'Filter' | translate}}</button>
            <button type="button" class="btn btn-primary" *ngIf="hasExtraFiltersApplied()"
                (click)="cleanExtraFilters();modalRef.hide();">{{'Restore Filters' | translate}}</button>
        </div>
    </div>

</ng-template>