<div *ngIf="isAllowed();else notAccess">

    <div *ngIf="clientId; else url">
        <a [title]="title" (click)="changeClient(clientId)">{{name}}</a>
    </div>

    <ng-template #url>
        <a [title]="title" routerLink="{{ link | lowercase }}">{{name}}</a>
    </ng-template>
</div>

<ng-template #notAccess>
    <span [title]="title">{{name}}</span>
</ng-template>


