<div #styleContainer></div>
        <div class="error-container">
            <div class="danger-sign"></div>
            <div class="error-message">
                <span>
                    <p><b>MARCAÇÃO INVÁLIDA</b>
                    </p>
                    <p> POR FAVOR VERIFIQUE A DATA/HORA E O CÓDIGO
                        DA MESMA<br>
                        OBRIGADO.</p>
                </span>
            </div>
        </div>
