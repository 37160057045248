<breadcrumb [routeBreadcrumb]="breadcrumb"></breadcrumb>

<div class="col-md-12">
    <div class="col-md-12">
        <button (click)="goBack()" class="btn btn-primary pull-right">{{'Back' | translate}}</button>
    </div>
    <div class="ticket">
        <h1>{{getTicketTitle()}}</h1>
        <div class="ticket-data">
            <div class="left">
                <div class="fields">
                    <div>{{'Emission' | translate}}:</div>
                    <div>{{'Via' | translate}}:</div>
                    <div>{{'Identification' | translate}}:</div>
                    <div>{{'Appointment' | translate}}:</div>
                </div>
                <div class="fields">
                    <div>{{parseDate(ticket?.dateCreated)}}</div>
                    <div>{{ticket?.via}}</div>
                    <div>{{ticket?.identification ? ticket.identification : ('No' | translate)}}</div> 
                    <div>{{ticket?.appointmentCode ? ('Yes' | translate) : ('No' | translate)}}</div>
                </div>
            </div>
            <div class="right">
                <div class="fields">
                    <div>{{'Scheduled Appointment Date' | translate}}:</div>
                </div>
                <div class="fields">
                    <div>{{parseDate(ticket?.meetingDate)}}</div>
                </div>
            </div>
        </div>
        
        <div class="bold uppercase">{{'Callings' | translate}}</div>
        <table *ngIf="ticket?.history.length">
            <thead>
                <tr>
                    <th>{{'Service' | translate}}</th>
                    <th>{{'Work Position' | translate}}</th>
                    <th>{{'Employee' | translate}}</th>
                    <th>{{'Call' | translate}}</th>
                    <th>{{'Conclusion' | translate}}</th>
                    <th>{{'Call Time' | translate}}</th>
                    <th>{{'Waiting Time' | translate}}</th>
                    <th>{{'Stayingtime Time' | translate}}</th>
                    <th>{{'Typology' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let historyItem of ticket?.history">
                    <td [attr.data-label]="'Service' | translate">{{historyItem?.service?.name}}</td>
                    <td [attr.data-label]="'Work Position' | translate">{{historyItem?.user?.location?.name}}</td>
                    <td [attr.data-label]="'Employee' | translate">{{historyItem?.user?.name}}</td>
                    <td [attr.data-label]="'Call' | translate">{{parseDate(historyItem?.callDate)}}</td>
                    <td [attr.data-label]="'Conclusion' | translate">{{parseDate(historyItem?.endDate)}}</td>
                    <td [attr.data-label]="'Call Time' | translate">{{formatDuration(historyItem?.time)}}</td>
                    <td [attr.data-label]="'Waiting Time' | translate">{{formatDuration(historyItem?.waitingTime)}}</td>
                    <td [attr.data-label]="'Stayingtime Time' | translate">{{formatDuration(historyItem?.stayingTime)}}</td>
                    <td [attr.data-label]="'Typology' | translate">{{historyItem.servicetype ? historyItem.servicetype.name : 'N/A'}}</td>
                </tr>
            </tbody>
        </table>

        <div class="no-data" *ngIf="!ticket?.history.length">{{'No Callings' | translate}}</div>

        <div class="bold uppercase">{{'Transfers' | translate}}</div>
        <table *ngIf="ticket?.switchUserHistory?.length">
            <thead>
                <tr>
                    <th>{{'Service' | translate}}</th>
                    <th>{{'Employee' | translate}}</th>
                    <th>{{'Date' | translate}}</th>
                    <th>{{'Origin' | translate}}</th>
                    <th>{{'Promoter' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let transferItem of ticket?.switchUserHistory; let i = index">
                    <td [attr.data-label]="'Service' | translate">{{ticket?.history && ticket?.history[i].service?.alias}}</td>
                    <td [attr.data-label]="'Employee' | translate">{{transferItem?.to}}</td>
                    <td [attr.data-label]="'Date' | translate">{{parseDate(transferItem?.date)}}</td>
                    <td [attr.data-label]="'Origin' | translate">{{transferItem?.from}}</td>
                    <td [attr.data-label]="'Promoter' | translate">{{transferItem?.whoDragged}}</td>
                </tr>
            </tbody>
        </table>

        <div class="no-data" *ngIf="!ticket?.switchUserHistory?.length">{{'No Transfers' | translate}}</div>

        <div class="bold uppercase">{{'Cancellations' | translate}}</div>
        <table *ngIf="ticket?.cancelHistory?.length">
            <thead>
                <tr>
                    <th>{{'Service' | translate}}</th>
                    <th>{{'Work Position' | translate}}</th>
                    <th>{{'Employee' | translate}}</th>
                    <th>{{'Date' | translate}}</th>
                    <th>{{'Promoter' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cancelItem of ticket?.cancelHistory">
                    <td [attr.data-label]="'Service' | translate">{{cancelItem?.cancelledCall?.service?.alias}}</td>
                    <td [attr.data-label]="'Work Position' | translate">{{cancelItem?.cancelledBy?.location?.name}}</td>
                    <td [attr.data-label]="'Employee' | translate">{{cancelItem?.cancelledCall?.user?.name}}</td>
                    <td [attr.data-label]="'Date' | translate">{{parseDate(cancelItem?.date)}}</td>
                    <td [attr.data-label]="'Promoter' | translate">{{cancelItem?.whoCanceled?.name}}</td>
                </tr>
            </tbody>
        </table>

        <div class="no-data" *ngIf="!ticket?.cancelHistory?.length">{{'No Cancellations' | translate}}</div>
    </div>

    <button *ngIf="isSuperUser()" (click)="toggleRawData()"
        type="button" class="btn btn-primary">
        {{ showRaw ? ('Hide raw data' | translate) : ('Show raw data' | translate) }} 
    </button>

    <pre *ngIf="showRaw">{{ ticket | json}}</pre>
</div>
