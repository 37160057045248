<header *ngIf="pageReady && clientSettings" [clientSettings]="clientSettings"></header>
<div *ngIf="pageReady" class="app-content">
  <side-menu></side-menu>
  <div class="app-row">
    <div *ngIf="pageReady && showBreadCrumb">
        <breadcrumb  [routeBreadcrumb]="breadcrumbArray"></breadcrumb>
    </div>
    <router-outlet (activate)='setBreadcrumbs($event)'></router-outlet>
  </div>
</div>
<footer [clientSettings]="pageReady && clientSettings"></footer>
<app-spinner></app-spinner>

