<breadcrumb  [routeBreadcrumb]="breadcrumb"></breadcrumb>

<h2 *ngIf="dataReady" >{{getTitle()}} </h2>

<filter-bar [ngClass]="{'hidden': !displayFilterBar}" *ngIf="paramsReady && dataReady" [filterBarParams]="filterBarParams" (updateEmitter)="filterBarListner($event)"></filter-bar>


<div *ngIf="hasSearch && dataReady" class="row">
  <div class="col-md-6 col-md-offset-3">
    <input type="text" class="form-control" placeholder="{{'Search of by fields' | translate}}: {{getSearchPlaceholder()}}" (keyup)="generateSearchQuery()" [(ngModel)]="searchQuery">

</div>
<button [class]="hasExtraFiltersApplied() ? 'btn btn-warning' : 'btn btn-primary'" *ngIf="hasExtraFilters" (click)="openExtrafiltersModal(template)">
    <i title="filtros" class="list-option-endpoint fa fa-filter" ></i>
    <span *ngIf="hasExtraFiltersApplied(); else NotFilters">{{'Advanced Filters' | translate}}{{'*'}}</span>
    <ng-template #NotFilters>
        <span>{{'Advanced Filters' | translate}}</span>
    </ng-template>
</button>


</div>
<div *ngIf="dataReady" class="row">
  <div class="col-md-12">
    <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="text-center" *ngFor="let param of getParamsByPermissions()">
                {{param.alias | translate}}
                <span (click)="sortField(param.attr)" *ngIf="param.options.sortable" class="sort-btn fa" [ngClass]="{'fa-sort-desc': sort.param == param.attr && sort.order == 'desc', 'fa-sort-asc': sort.param == param.attr && sort.order == 'asc', 'fa-sort': sort.param != param.attr}"></span></th>
              <th *ngIf="hasListOptions()"><span class="fa fa-cog"></span></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of results">
              <td *ngFor="let param of getParamsByPermissions()">
                  <row-model class="text-center" [record]="item" [param]="param" [model]="modelInstance"></row-model>
              </td>
              <td *ngIf="hasListOptions()">
                  <div class="tools-row">

                      <list-option-device-tasks *ngFor="let option of getItemOptions('device-tasks', item)" [Model]="Model" [item]="item" [option]="option"></list-option-device-tasks>
                      <list-option-url *ngFor="let option of getItemOptions('url', item)" [Model]="Model" [item]="item" [option]="option" [agencyId]="agencyId"></list-option-url>
                      <user-tasks *ngFor="let option of getItemOptions('user-tasks', item)" (taskSent)="getResults()" [Model]="Model" [item]="item" [option]="option"></user-tasks>
                      <list-option-endpoints *ngFor="let option of getItemOptions('endpoints', item)" [Model]="Model" [item]="item" [option]="option"></list-option-endpoints>
                      <list-option-post-confirm *ngFor="let option of getItemOptions('post-confirm', item)" [Model]="Model" [item]="item" [option]="option"></list-option-post-confirm>
                      <a *ngIf="downloadSettings(item)" [href]="downloadSettings(item)" class="fa fa-download"></a>
                    </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-component [currentPage]="currentPage" [lastPage]="lastPage" [totalRecords]="totalRecords" [recordsPerPage]="recordsPerPage" (onPageChage)="callPage($event)"></pagination-component>
  </div>
</div>

<div class="container-fluid" *ngIf="displayBottomContainer">
  <toolbar [Model]="Model" [items]="items" [filterAgencies]="filterAgencies" [agencyId]="agencyId"></toolbar>
</div>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'Advanced filters'| translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="form-group">
            <div *ngIf="hasExtraFilters">
                <extra-filter
                (valueChanged)="extraFilterChanged($event)"
                (selectedFiltersEmmitter)="selectedExtraFiltersChanged($event)"
                *ngFor="let extraFilter of modelInstance.extraFilters"
                [selectedFilters]="getSelectedExtraFiltersItems(extraFilter)"
                [relations]="modelInstance.relations"
                [filterDefinition]="extraFilter"></extra-filter>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="modal-footer">
          <div class="form-group">
              <button type="button" class="btn btn-primary" (click)="preGetResults();modalRef.hide();saveExtraFilters()">{{'Filter' | translate}}</button>
              <button type="button" class="btn btn-primary" *ngIf="hasExtraFiltersApplied()" (click)="cleanExtraFilters();modalRef.hide();">{{'Restore Filters' | translate}}</button>
          </div>
      </div>

    </ng-template>