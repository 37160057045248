<div *ngIf="sentence" class="hover-c">
  <span class="hovertext-c">{{sentence | translate}}</span>
</div>
<div *ngIf="values" class="hover-c">
  <span>...</span>
  <span class="hovertext-c">
      <ul>
          <li *ngFor="let value of values">{{value | translate}}</li>
      </ul>
  </span>
</div>
