<label [formGroup]="formGroup" for="{{identifier}}" class="col-md-12">
    <span *ngIf="!hideLabel" [ngClass]="{'error-label': error}">{{ translateTerm(param.alias) }}</span> <tooltip-component [sentence]="param.tooltip"></tooltip-component>
    <note [text]="param.note"></note>
    <p *ngIf="error" [ngClass]="{'error-label': error}"><small [innerHTML]="error"></small></p>
    <input id="{{identifier}}" [readOnly]="isReadOnly()" (change)="changeValue($event)"  [attr.disabled]="getDisabledByUserDisabled()" [name]="param.attr" #fileInput [ngClass]="{'colorPicker': param.type == 'color', 'error-input': error}" [formControlName]="param.attr" (change)="setImage($event)" type="{{param.type}}" class="form-control" placeholder="{{ translateTerm(param.alias) }}">
    {{setValue()}}
</label>
<div class="col-md-12"  *ngIf="param.type == 'file' && record && record[param.attr]">
    <img [src]="getImage()" alt="">
</div>
