<label for="{{identifier}}" class="col-md-12" [formGroup]="formGroup">
  <span [ngClass]="{'error-label': error}">{{param.alias}}</span> <tooltip-component [sentence]="param.tooltip"></tooltip-component>
  <note [text]="param.note"></note>  
  <p *ngIf="error" [ngClass]="{'error-label': error}"><small [innerHTML]="error"></small></p>

<label class="col-md-12" for="select-all-{{param.identifier}}-{{param.attr}}">
  <input type="checkbox" (change)="selectAll($event)" id="select-all-{{param.identifier}}-{{param.attr}}">
  Selecionar tudo
</label>

<span [formArrayName]="param.attr">
    <label class="col-md-12" *ngFor="let item of getItems(); let i = index;" for="{{identifier}}-{{param.attr}}">
        <input type="checkbox" (change)="setChecked(i)" [checked]="item.checked" class="{{identifier}}-checkbox" value="{{item.value.key}}" id='{{identifier}}-checkbox-{{i}}' name="{{param.attr}}[]">
        <label for="{{identifier}}-checkbox-{{i}}">
            {{item.value.value}}
        </label>
      </label>
</span>

</label>
