  <form [formGroup]="formGroup">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>{{ param.alias | translate}}</th>
        </tr>
      </thead>
  
      <!-- Bind the TBODY to formArrayName="messages" (or whatever param.attr is) -->
      <tbody formArrayName="{{ param.attr }}" style="width: 100%;">
        <!-- Use a single *ngFor for the main row and the expanded row -->
        <ng-container
          *ngFor="let rowCtrl of mainArray.controls; let i = index"
          [formGroupName]="i"
        >
          <!-- 1) Main row -->
          <tr >
            <td class="col-md-9">
              <!-- The "message" (or param.singleAttr) control -->
              <input
                type="text"
                formControlName="{{ param.singleAttr }}"
                class="form-control"
                placeholder="{{param.singleAlias | translate}}"
              />
              <div class="error-label" *ngIf="fieldError(param.attr + '.' + i + '.' + param.singleAttr)">
                {{ fieldError(param.attr + '.' + i + '.' + param.singleAttr) }}
              </div>
            </td>
            <td class="col-md-1">
              <!-- Button to toggle expand/collapse -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="toggleRow(i)"
                title="{{'See' | translate}} {{'Translation' | translate}}"
                >
                <i
                  class="fa"
                  [ngClass]="expandedRows[i] ? 'fa-chevron-up' : 'fa-chevron-down'"
                ></i>
              </button>
            </td>
            <td class="col-md-1">
                <input
                type="number"
                formControlName="order"
                class="form-control"
                placeholder="{{'Order' | translate}}"
              />
            </td>
            <td class="col-md-1">
                <button
                    type="button"
                    class="btn btn-danger"
                    (click)="removeRow(i)"
                >
                    <i class="fa fa-trash"></i>
                </button>
            </td>
          </tr>
  
          <!-- 2) Expanded row, only displayed if expandedRows[i] is true -->
          <tr *ngIf="expandedRows[i]">
            <td colspan="3">
              <div formArrayName="translations" class="translations-wrapper">
                <!-- Loop over each translation row in this row's 'translations' array -->
                <div
                  class="translation-row"
                  *ngFor="let tCtrl of translationsArray(i).controls; let j = index"
                  [formGroupName]="j"
                >
                  <div class="row">
                    <div class="select-container">
                      <select class="form-control" formControlName="language" style="max-width: fit-content;">
                        <option value="">{{'Language' | translate}}</option>
                        <option
                          *ngFor="let lang of availableLanguages"
                          [value]="lang.language_code"
                        >
                          {{ lang.alias }}
                        </option>
                      </select>

                      <div class="error-label" *ngIf="fieldError(param.attr + '.' + i + '.' + param.subAttr + '.' + j +  '.language')">
                        {{ fieldError(param.attr + '.' + i + '.' + param.subAttr + '.' + j + '.language') }}
                      </div>
                    </div>
                    <div class="col-md-9" style="padding-right: 0 !important;padding-left: 0!important;flex:1">
                        <input
                        type="text"
                        class="form-control"
                        formControlName="text"
                        placeholder="{{'Translation' | translate}}"
                      />

                      <div class="error-label" *ngIf="fieldError(param.attr + '.' + i + '.' + param.subAttr + '.' + j +  '.text')">
                        {{ fieldError(param.attr + '.' + i + '.' + param.subAttr + '.' + j +  '.text') }}
                      </div>
                    </div>
                    <div class="col-md-1">
                      <button
                        type="button"
                        class="btn btn-danger"
                        (click)="removeTranslation(i, j)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- Button to add a new translation to this row -->
               
            </td>
            <td>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="addTranslationByIndex(i)"
                  title="{{'Add' | translate}} {{'Translation' | translate}}"
                >
                  <i class="fa fa-plus"></i> 
                </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  
    <!-- Button to add a new main row -->
    <div class="text-right mt-3 button-container">
      <button type="button" class="btn btn-success" (click)="addRow({})">
        <i class="fa fa-plus"></i> {{'Add' | translate}} {{param.singleAlias | translate}}
      </button>
    </div>
  </form>
  