
<breadcrumb  [routeBreadcrumb]="breadcrumbArray"></breadcrumb>
<h2>{{translateTerm('Services')}} {{translateTerm('of')}} {{getTitle()}}</h2>
<div class="col-md-9">

<div class="table-responsive main-container" style="overflow-x: unset;">

    <table class="table table-striped">
        <thead>
            <tr>
                <th>{{translateTerm('Service')}}</th>
                <th style="text-align: center;">{{translateTerm('General')}} <tooltip-component [sentence]="getTooltip('services')"></tooltip-component></th>
                <th style="text-align: center;">{{translateTerm('Prioritized')}} <tooltip-component [sentence]="getTooltip('prioritized_services')"></tooltip-component></th>
                <th style="text-align: center;">{{translateTerm('Backup')}} <tooltip-component [sentence]="getTooltip('backup_services')"></tooltip-component></th>
                <th style="text-align: center;">{{translateTerm('Transferable')}} <tooltip-component [sentence]="getTooltip('transferable_services')"></tooltip-component></th>
                <th style="text-align: center;">{{translateTerm('Weighted')}} <tooltip-component [sentence]="getTooltip('weighted_services')"></tooltip-component></th>
                <th style="text-align: center;">{{translateTerm('Disabled')}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let service of services">
                <td>{{service.name}}</td>
                <td style="text-align: center;"><input (change)="controlRadio($event)" id="{{service.id}}_services"  type="radio" name="{{service.id}}" value="services" [disabled]="readOnly"></td>
                <td style="text-align: center;"><input (change)="controlRadio($event)" id="{{service.id}}_prioritized_services"  type="radio" name="{{service.id}}" value="prioritized_services" [disabled]="readOnly"></td>
                <td style="text-align: center;"><input (change)="controlRadio($event)" id="{{service.id}}_backup_services"  type="radio" name="{{service.id}}" value="backup_services" [disabled]="readOnly"></td>
                <td style="text-align: center;"><input (change)="controlRadio($event)" id="{{service.id}}_transferable_services"  type="radio" name="{{service.id}}" value="transferable_services" [disabled]="readOnly"></td>
                <td style="text-align: center;">
                    <input (change)="controlRadio($event)" id="{{service.id}}_weighted_services"  type="radio" name="{{service.id}}" value="weighted_services" [disabled]="readOnly">
                    <input
                        (change)="updateResult()"
                        (keyup)="updateResult()"
                        (keydown)="updateResult()"
                        id="{{service.id}}_weighted_services.weight"
                        type="number" name="{{service.id}}_weight"
                        [(ngModel)]="weights[service.id]"
                        [ngClass]="{'hidden-weight': !isSelected(service.id, 'weighted_services')}"
                        [disabled]="readOnly || !isSelected(service.id, 'weighted_services')"
                    >
                </td>
                <td style="text-align: center;"><input (change)="controlRadio($event)" id="{{service.id}}_not_applicable"  type="radio" name="{{service.id}}" value="not_applicable" [disabled]="readOnly"></td>
            </tr>
        </tbody>
    </table>
    <div [hidden]="readOnly">
        <div class="form-btn-padding pull-left">
            <button class="btn btn-success" (click)="submitResult()">{{translateTerm('Save')}}</button>
        </div>
        <div class="form-btn-padding pull-left">
            <a routerLink="/usergroups" class="btn btn-danger">{{translateTerm('Cancel')}}</a>
        </div>
    </div>
</div>
</div>
<div class="col-md-3">
    <queue-preview [services]="currentServices" [rawServices]="services"></queue-preview>    
</div>
