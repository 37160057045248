
<div id="inquiry-main-container" class="inquiry-main-container" [style.background-image]="getImage('main_background-image') ? 'url('+getImage('main_background-image') +')' : ''" >
    <div class="header-bar" id="header-bar">
        <div id="inquiry-header-logo" class="inquiry-header-logo" *ngIf="getImage('header_image')" [style.background-image]="getImage('header_image') ? 'url('+getImage('header_image') +')' : ''" ></div>
        <div id="header-message" class="header-message">{{getConfig('header-message')}}</div>
        <div class="back-button" id="back-button" *ngIf="back">
            <fa name="angle-left"></fa>
            <div class="back-button-message" id="back-button-message">{{getConfig('back-button-text')}}</div>
        </div>
    </div>

    <div class="inquiry-content" id="inquiry-content">
        <div *ngIf="inquiry" class="questions-page-content">
            <div class="questions-title">
              {{inquiry.title}}
            </div>
            <div class="hidden" *ngIf="paginationHelper && paginationHelper.currentPage.length > 0"></div>
            <form [formGroup]="formGroupInstance" class="questions-section" id="questions-section" *ngIf="paginationHelper && paginationHelper.currentPage.length > 0">
              <div class="question-content" id="question-content">
                <div class="question" *ngFor="let question of paginationHelper.currentPage">
                  <div class="question-title">
                    {{question.question}}
                  </div>
                  <inquiry-response-inputs [formGroupInstance]="formGroupInstance" [cssContent]="cssContent" [question]="question"></inquiry-response-inputs>
                </div>
              </div>
              <div class="error">{{error}}</div>
              <div class="submit-questions-button-container" id="submit-questions-button-container">

                <a *ngIf="paginationHelper.hasPrevious()" class="back-questions-button" id="back-questions-button" (click)="backButton()">
                  Voltar
                </a>

                <a *ngIf="paginationHelper.hasNext(); else notHaveNext" class="next-questions-button" id="next-questions-button" (click)="forwardButton()">
                  Seguinte
                </a>

                <ng-template #notHaveNext>
                  <button type="submit" class="submit-questions-button" id="submit-questions-button" (click)="forwardButton()">
                    Continuar
                  </button>
                </ng-template>

              </div>
            </form>
          </div>
          
    </div>

    <div id="inquiry-translations-area" class="inquiry-translations-area">
        <div id="inquiry-flags-container" class="inquiry-flags-container">
            <img src="/assets/images/flags/en.png">
            <img src="/assets/images/flags/pt.png">
        </div>
    </div>

    <div id="inquiry-footer" class="inquiry-footer"  [style.background-image]="getImage('footer_image') ? 'url('+getImage('footer_image') +')' : ''" >
    </div>

    <!-- footer_image -->