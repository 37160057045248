<input-default *ngIf="getInputTemplate() == 'input'" [Model]="Model" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [param]="param"></input-default>
<textarea-input *ngIf="getInputTemplate() == 'textarea'" [Model]="Model" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [param]="param"></textarea-input>
<dropdown *ngIf="getInputTemplate() == 'dropdown'" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [relations]="getRelations()" [Model]="Model" [param]="param"></dropdown>
<dropdown-simple *ngIf="getInputTemplate() == 'dropdown-simple'" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [relations]="getRelations()" [Model]="Model" [param]="param"></dropdown-simple>
<agency-input (changeValueEmitter)="changeAgency($event)" *ngIf="getInputTemplate() == 'agency-input'" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [relations]="getRelations()" [Model]="Model" [param]="param"></agency-input>
<agency-input-multiple (changeValueEmitter)="changeAgency($event)" *ngIf="getInputTemplate() == 'agency-input-multiple'" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [relations]="getRelations()" [Model]="Model" [param]="param"></agency-input-multiple>
<checkbox-list *ngIf="getInputTemplate() == 'checkbox-list'" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [relations]="getRelations()" [Model]="Model" [param]="param"></checkbox-list>
<client-chartgroup-checkbox-list *ngIf="getInputTemplate() == 'client-chartgroup-checkbox-list'" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [relations]="getRelations()" [Model]="Model" [param]="param"></client-chartgroup-checkbox-list>
<checkbox-single *ngIf="getInputTemplate() == 'checkbox-single'" [recordId]="recordId" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [param]="param" [Model]="Model"></checkbox-single>
<hidden-field *ngIf="getInputTemplate() == 'hidden'" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [param]="param"></hidden-field>
<relational-models *ngIf="getInputTemplate() == 'relational-models'" [error]="translatedError()" [relations]="relations" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [param]="param"></relational-models>
<binary-array-field *ngIf="getInputTemplate() == 'binaryArray'" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [relations]="getRelations()" [Model]="Model" [param]="param"></binary-array-field>
<inquiry-questions *ngIf="getInputTemplate() == 'inquiry-questions'" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [param]="param"></inquiry-questions>
<image-uploader [Model]="Model" *ngIf="getInputTemplate() == 'image-uploader'" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [param]="param" multiple="0"></image-uploader>
<audio-uploader [Model]="Model" *ngIf="getInputTemplate() == 'audio-uploader'" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [param]="param" multiple="0"></audio-uploader>
<image-uploader [Model]="Model" *ngIf="getInputTemplate() == 'image-uploader-multiple'" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [param]="param" multiple="1"></image-uploader>
<many-form *ngIf="getInputTemplate() == 'many-form'" [record]="record"  [error]="translatedError()" [param]="param" [record]="record" [formGroup]="formGroup" [Model]="Model"></many-form>
<div class="col-md-3 form-group" *ngIf="getInputTemplate() == 'totpauth'">
    <a [routerLink]="[ '/totp/setup']" class="btn btn-md btn-primary btn-block btn-signin">{{'Check 2FA' | translate}} <i class="fa fa-external-link" aria-hidden="true"></i>
    </a>
</div>
<time-picker *ngIf="getInputTemplate() == 'time'" [Model]="Model" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [param]="param"></time-picker>
<date-picker *ngIf="getInputTemplate() == 'date'" [Model]="Model" [error]="translatedError()" [record]="record" [formGroup]="formGroup" [identifier]="getIdentifier()" [param]="param"></date-picker>
<expandable-languages-table
  *ngIf="getInputTemplate() == 'expandable-languages-table'"
  [error]="translatedError()"
  [record]="record"
  [formGroup]="formGroup"
  [identifier]="getIdentifier()"
  [param]="param"
  [submitErrors]="submitErrors"
  [Model]="Model">
</expandable-languages-table>
