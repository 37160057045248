<breadcrumb [routeBreadcrumb]="breadcrumb"></breadcrumb>

<div class="row">
    <div class="col-md-12">
        <h1>{{title | translate}} - {{getAgencyName()}}</h1>
    </div>
    <div class="col-md-12">
        <button class="btn btn-primary" id="fast" (click)="changeViewFast()">{{'Last_' | translate}} 10</button>
        <button class="btn btn-primary" id="search" (click)="changeSearch()">{{'Search' | translate}}</button>
    </div>
    <transactions-search *ngIf="isOnSearch()" (paramsSearchForm)="addParams($event)" [searchForm]="searchForm" [device]="device"></transactions-search>
    <div class="col-md-12 table-responsive">
        <transactions-table [transactionArray]="results" [deviceId]="deviceId"></transactions-table>
    </div>

</div>