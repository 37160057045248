<form [formGroup]="form">
    <div class="form-group col-md-12 row" style="margin-top: 10px;">
        <div class="col-md-3">
            <label>{{ 'Type' | translate}}</label>
            <select class="form-control" formControlName="transactionType">
                <option value="">{{'Choose an option' | translate}}</option>
                <option *ngFor="let type of types" value="{{type.value}}">{{type.alias | translate}}</option>
            </select>
        </div>
        <div class="col-md-3">
            <label>{{'Origin' | translate}}</label>
            <select class="form-control" formControlName="transactionOrigin">
                <option value="">{{'Choose an option' | translate}}</option>
                <option *ngFor="let origin of origins" value="{{origin.value}}">{{origin.alias | translate}}</option>
            </select>
        </div>
        <div class="col-md-3">
            <label class="col-md-12">{{'Date' | translate}}</label>
            <input type="text" placeholder="Datepicker" formControlName="date" class="form-control" bsDatepicker [bsValue]="bsValue" value="{{getDateToFormat()}}" [bsConfig]="{ isAnimated: true, dateInputFormat: 'YYYY-MM-DD', containerClass:'theme-blue' }" >
        </div>
        <div class="col-md-3">
            <label for="showNullClosures">{{'Show closures with value 0' | translate}}</label>
            <div>
                <input  id="showNullClosures" formControlName="nullClosures" type="checkbox">
            </div>
        </div>
    </div>
    <div class="form-group col-md-12 row">
        <div class="col-md-3">
            <label
                title="{{'Order that the tickets were inserted in the system' | translate}}">{{'Insertion Order' | translate}}</label>
            <select id="date-from" class="form-control" formControlName="sortFrom">
                <option value="">{{'Choose an option' | translate}}</option>
                <option value="1">{{'Ascending' | translate}}</option>
                <option value="-1">{{'Descending' | translate}}</option>
            </select>
        </div>
        <div class="col-md-3">
            <label title="{{'Desired number of results' | translate}}">{{'Number of Results' | translate}}</label>
            <select id="date-from" class="form-control" formControlName="numberOfResults">
                <option value="">{{'Choose an option' | translate}}</option>
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
            </select>
        </div>


    </div>
</form>