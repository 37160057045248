<a *ngIf="editMode()" title="{{'Edit Note' | translate}}" class="list-option-endpoint fa fa-pencil" (click)="openModal(template)"></a>
<button *ngIf="createMode()" title="{{'Create Note' | translate}}" (click)="openModal(template)"
    class="btn btn-primary tasks-button"><span class="fa fa-pencil"></span> {{'Create Note' | translate}}</button>
<ng-template #template>
    <div class="modal-header">
        <div class="col-xs-12 text-center">
            <h4 class="modal-title pull-left"><b>{{createMode()? 'Create Note' : 'Edit Note' | translate}}</b></h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <form class="form-group" (submit)="updatePassword($event)">
            <div class="col-md-12 form-group"><br>
                <label for="note-text" class="col-md-12">
                    <span [ngClass]="{'error-label': error}">{{'Note body' | translate}}</span>
                    <textarea class="form-control" name="note" id="note-text" rows="5" cols="10"
                        [(ngModel)]="noteText"></textarea>

                </label>

            </div>
            <div class="col-md-12 form-group"><br>
                <label for="private-flag" class="form-element-spacing">
                <span [ngClass]="{'error-label': error}" class="label-checkbox-list">{{ 'private' | translate }}</span>
                    <input id="private-flag" name="private" [(ngModel)]="privateFlag" type="checkbox">
                </label>
            </div>
            <div class="col-md-12 form-group"><br>
                <button type="button" (click)="submit()"
                    class="btn btn-success btn-default pull-right">{{'Submit' | translate}}</button>
                <button type="button" *ngIf="editMode()" (click)="deleteNote()"
                    class="btn btn-danger btn-default">{{'Delete' | translate}}</button>
            </div>
        </form>
    </div>
</ng-template>