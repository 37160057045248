<label [formGroup]="formGroup" for="{{identifier}}" class="col-md-12">
    <span [ngClass]="{'error-label': error}">{{param.alias}}</span>
    <tooltip-component [sentence]="param.tooltip"></tooltip-component>
    <note [text]="param.note"></note>
    <div class="col-md-12" style="padding:0 !important; margin:10px 0 0 0 !important;">
        <audio id="{{getPreviewId()}}" *ngIf="audioPreview" class="audio-preview" controls>
            <source [src]="audioPreview" type="audio/mp3">
            <source [src]="audioPreview" type="audio/wav">
            Your browser does not support the audio element.
        </audio>
    </div>
    <p *ngIf="error" [ngClass]="{'error-label': error}">
        <small [innerHTML]="error"></small>
    </p>
    
    <div *ngIf="isAudio()" class="col-md-12" style="padding:0 !important; margin:10px 0 0 0 !important;">
        <div class="col-md-9" style="padding:0 !important; margin:0 0 0 0 !important;">
            <input id="{{identifier}}" multiple (change)="showInfo($event)" [name]="param.attr" #fileInput type="file"
                class="form-control" placeholder="{{param.alias}}">
            <input type="hidden" [formControlName]="param.attr">
        </div>
        <div class="col-md-2">
            <button type="button" class="btn btn-danger" (click)="removeAudio(identifier)">
                <span class="glyphicon glyphicon-trash"></span>
            </button>
        </div>
    </div>
    <ul *ngIf="hasPreview()">
        <li *ngFor="let file of getFilesForPreview()">
            <a href="{{getFileLink(file)}}">{{getFileAlias(file)}}</a>
        </li>
    </ul>
</label>
<label class="col-md-12">
    <span>{{'Use TTS to generate sound'|translate}}</span>
    <div class="col-md-12" style="padding:0 !important; margin:0 0 0 0 !important;">
        <input type="text" [(ngModel)]="ttsInput" class="form-control">
    </div>
    <div class="col-md-12" style="padding:0 !important; margin:10px 0 0 0 !important;">
        <button type="button" class="btn btn-primary" (click)="generateAudio()">Generate Audio</button>
    </div>
</label>