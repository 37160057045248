<div class="login">
    <div class="logo">
        <img class="logo-img" src="/assets/images/way2queuemanagement_logo.svg" alt="">
    </div>
    <div class="panel panel-default login-panel">
        <div class="panel-header">
            <div class="panel-heading login-title">
                <div class="col-xs-12">
                    <span class="glyphicon glyphicon-lock"></span> {{ title | translate}}
                </div>
            </div>
        </div>
        <div class="panel-body">
            <div class="col-xs-12">
                <div class="alert alert-success" role="alert" *ngIf="submitted && confirmed">
                    <ul>
                        <li>
                            {{'Account reset done successfully' | translate }}
                        </li>
                    </ul>
                </div>
                <div class="alert alert-danger" role="alert" *ngIf="submitted && !confirmed">
                    <ul>
                        <li>
                            {{'Account reset failed' | translate }}
                        </li>
                    </ul>
                </div>
                <form (submit)="accountResetRequest($event)">
                    <div class="alert alert-danger" *ngIf="hasErrors()" role="alert">
                        <ul>
                            <li *ngFor="let error of getErrors()" [innerHTML]="error"></li>
                        </ul>
                    </div>
                    <div *ngIf="!submitted" class="form-group"><br>

                        <label for="">{{'New Password' | translate}}</label>
                        <input type="password" id="loginPassword" class="form-control"
                            (keyup)="updatePasswordValue($event)">
                    </div>
                    <div *ngIf="!submitted" class="form-group"><br>
                        <label for="">{{'Confirm Password' | translate}}</label>
                        <input type="password" id="loginPassword" class="form-control"
                            (keyup)="updatePasswordRepeatValue($event)">
                    </div>
                    <div class="form-group"><br>
                        <button *ngIf="!submitted" type="submit"
                            class="btn btn-submit btn-default pull-right">{{'Submit' | translate}}</button>
                        <button class="btn btn-submit btn-default" (click)="login()">Login</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-xs-12"></div>
</div>