
<div #styleContainer></div>

<div id="kiosk-buttons-container">
    <div id="kiosk-main-container-title" class="readcard-finish-message">
        {{ getValue('cardid-cardid_finish_message') }}
    </div>
    <div id="kiosk-main-container-title" class="readcard-finish-submessage">
        {{ getValue('cardid-card_id_finish_submessage') }}
    </div>
</div>